import styled from "styled-components";
import { motion } from "framer-motion";
// globalstyles
import GlobalStyles from "../../theme/GlobalStyles";

export const TaskBarIcon = styled.div`
  position: absolute;
  top: 20%;
  right: 0px;
  .ContainerIconTaskBar {
    background: #fff;
    padding-right: 10px;
    padding-left: 10px;
    padding-top: 8px;
    padding-bottom: 7px;
    margin-bottom: 10px;
    border-radius: 20px 0px 0px 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    cursor: pointer;
    position: relative;
  }
  .containerFloatDD {
    display: flex;
    flex-direction: row;
    position: absolute !important; 
    top: 0px;
    right: 0px;
    width: 1018px;
    justify-content: space-around;
    height: 33px;
    align-items: center;
  }
  .ContainerIconTaskBar {
  position: relative;
  display: flex;
}

.tooltip {
  visibility: hidden;
  top: 0px;
  right: 0px;
  width: 100%;
  height: 100%;
  border-radius: 20px 0px 0px 20px;
  background-color: #333;
  color: #fff;
  font-size: 13px;
  font-weight: bold;
  display: flex;
  align-items: center;
  text-align: center;
  position: absolute;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s;
}
.tooltip p {
  margin-left: 4px;
}

.ContainerIconTaskBar:hover .tooltip {
  visibility: visible;
  opacity: 1;
}
  // Mobil Frist
  @media screen and (min-width: 200px) {
  }
  @media screen and (min-width: 720px) {
  }
  @media screen and (min-width: 900px) {
  }
  @media screen and (min-width: 1200px) {
  }

  // LANSCAPE Mobil
  @media screen and (min-width: 838px) and (orientation: landscape) {
    // DESK
  }
  @media screen and (min-width: 930px) and (orientation: landscape) {
  }


`;
