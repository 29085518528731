import React, { useState } from "react";
import ArrowDown from "../../assets/images/menu/flechaabajo.svg";
import {
  ContainerTopBarDesk,
  ContainerTopBarMovil,
} from "../../components/home/TopBarStyles";

const Objetivocomponent = ({
  dataObjetivo,
  Tipo,
  apiImage,
  windowSize,
  HanlderSelectRouterMenu,
}) => {
  const [dataP, setdataP] = useState(dataObjetivo);
  const paragraphs = dataP?.copy?.split(".\n\nC");
  const [ShowFloatMenuHome, setShowFloatMenuHome] = useState(false);
  const [SelectedEvolucionar, setSelectedEvolucionar] = useState(
    "1. Es demasiada la cantidad de proyectos."
  );
  const [SelectedInovacion, setSelectedInovacion] = useState(
    "1. Centrado en el consumidor"
  );

  const hanlderSelectDropDown = (data) => {
    setSelectedEvolucionar(data);
   
  };
  const hanlderSelectDropDownInovacion = (data) => {
    setSelectedInovacion(data);
   
  };


  const HanlderShowFloatMenu = () => {
    if (ShowFloatMenuHome === true) {
      setShowFloatMenuHome(false);
    } else {
      setShowFloatMenuHome(true);
    }
  };

  const HanlerSelectedItem = (data) => {
    switch (data) {
      case "OBJETIVO":
        HanlderSelectRouterMenu("objetivo");
        setdataP();
        break;
      case "¿POR QUÉ EVOLUCIONAR?":
        HanlderSelectRouterMenu("porque-evolucionar");
        setdataP();
        break;
      case "¿POR QUÉ INNOVAR?":
        HanlderSelectRouterMenu("porque-inovar");
        setdataP();
        break;
      case "PRINCIPIOS DE INNOVACIÓN":
        HanlderSelectRouterMenu("principios-inovacion");
        setdataP();
        break;
      default:
        break;
    }
  };

 
  return (
    <div>
      {windowSize?.width <= 837 ? (
        <ContainerTopBarMovil>
          <div
            onClick={() => HanlderShowFloatMenu()}
            className="ContainerArrowDownItem"
          >
            <div
              className="ItemMenuTopBarMovil"
              style={{ marginBottom: -10, paddingBottom: 0, textTransform: 'uppercase' }}
            >
              <p style={{ color: "#3F348B" }}>{
              Tipo === "objetivo" ? 
              "objetivo" 
              : 
              Tipo === "porque-evolucionar" ? 
              "POR QUÉ EVOLUCIONAR" 
              : 
              Tipo === "porque-inovar" ? 
              "POR QUÉ INNOVAR" 
              : 
              Tipo === "principios-inovacion" ? 
              "PRINCIPIOS DE INNOVACIÓN" 
              : 
              Tipo
              }</p>
            </div>
            <div>
              <img src={ArrowDown} alt="ArrowDown" />
            </div>
          </div>

          <div
            style={
              ShowFloatMenuHome === true
                ? { display: "block" }
                : { display: "none" }
            }
            className="FloatMenuMovilHome"
          >
            <div className="ItemMenuTopBarMovil">
              <div 
              onClick={() => HanlerSelectedItem("OBJETIVO")}
              className="TopBarTodos">
                <p
                  style={
                    { color: "rgb(63, 52, 139)" }
                  }
                >
                  OBJETIVO
                </p>
              </div>
              <div onClick={() => HanlerSelectedItem("¿POR QUÉ EVOLUCIONAR?")}
              className="TopBarTodos">
                <p
                  style={
                    { color: "rgb(63, 52, 139)" }
                  }
                >¿POR QUÉ EVOLUCIONAR?</p>
              </div>
              <div onClick={() => HanlerSelectedItem("¿POR QUÉ INNOVAR?")}
              className="TopBarTodos">
                <p
                  style={
                    { color: "rgb(63, 52, 139)" }
                  }
                >¿POR QUÉ INNOVAR?</p>
              </div>
              <div onClick={() => HanlerSelectedItem("PRINCIPIOS DE INNOVACIÓN")}
              className="TopBarTodos">
                <p
                  style={
                    { color: "rgb(63, 52, 139)" }
                  }
                >PRINCIPIOS DE INNOVACIÓN</p>
              </div>
            </div>
          </div>
        </ContainerTopBarMovil>
      ) : (
        <ContainerTopBarDesk>
          <div
            onClick={() => HanlerSelectedItem("OBJETIVO")}
            className="TopBarTodos"
          >
            <p
              style={
                Tipo === "objetivo"
                  ? { color: "rgb(63, 52, 139)" }
                  : { background: "" }
              }
            >
              OBJETIVO
            </p>
          </div>
          <div
            onClick={() => HanlerSelectedItem("¿POR QUÉ EVOLUCIONAR?")}
            className="TopBarTodos"
          >
            <p
              style={
                Tipo === "porque-evolucionar"
                  ? { color: "rgb(63, 52, 139)" }
                  : { background: "" }
              }
            >
              ¿POR QUÉ EVOLUCIONAR?
            </p>
          </div>
          <div
            onClick={() => HanlerSelectedItem("¿POR QUÉ INNOVAR?")}
            className="TopBarTodos"
          >
            <p
              style={
                Tipo === "porque-inovar"
                  ? { color: "rgb(63, 52, 139)" }
                  : { background: "" }
              }
            >
              ¿POR QUÉ INNOVAR?
            </p>
          </div>
          <div
            onClick={() => HanlerSelectedItem("PRINCIPIOS DE INNOVACIÓN")}
            className="TopBarTodos"
          >
            <p
              style={
                Tipo === "principios-inovacion"
                  ? {  color: "rgb(63, 52, 139)" }
                  : { background: "" }
              }
            >
              PRINCIPIOS DE INNOVACIÓN
            </p>
          </div>
        </ContainerTopBarDesk>
      )}

      <div className="ContainerBodyObjetivo">
        <div className="CotainerFlexObjetivo">
          <div className="ContainerTitleObjetivo">
            <h2 className="">
              {Tipo === "objetivo"
                ? "Objetivo del proceso"
                : Tipo === "porque-evolucionar"
                ? "¿Por qué evolucionar?"
                : Tipo === "porque-inovar"
                ? "¿Por qué innovar?"
                : Tipo === "principios-inovacion"
                ? "Principios de Innovación"
                : ""}
            </h2>  
            <div className="lineObjetivoFases"></div>
            <div>
              {Tipo === "objetivo" ? (
                <div className="ContainerCopyObjetivo">
                  {/* {paragraphs?.map((paragraph, index) => (
                    <p key={index}>{paragraph}</p>
                  ))} */}
                  <p>Continuar con la evolución de la empresa, a partir de explorar, incursionar, desarrollar y asegurar nuestro liderazgo en la industria de alimentos y bebidas, para ser relevantes para el consumidor los siguientes 100 años.</p>
                  <p>Esta evolución del Macroproceso está enfocada en estandarizar los requerimientos comerciales y estratégicos del negocio para una administración efectiva del pipeline de iniciativas, su cultura y liderazgo habilitador enfocado a resultados, a través de:</p>
                  <p>Definición, alineación y visibilidad de criterios para que todos los proyectos garanticen impacto a la compañía.</p>
                  <p>Creación de equipo y foro multidisciplinario con liderazgo para toma de decisiones.</p>
                  <p>Creación de gobierno.</p>
                  <p>Implementación en 2023 de product portfolio management para control y ejecución de proyectos.</p>
                  <p>Alineación de lenguaje y cultura.</p>
                </div>
              ) : Tipo === "porque-evolucionar" ? (
                <div className="ContainerEvolucionarObjetivo">
                  <div className="ContainerSubtitleObjetivo">
                    <p>¿Por qué evolucionar el proceso?</p>
                  </div>
                  <div>
                    {dataP
                      ? dataP?.estructura?.map((r) => {
                          return (
                            <div className="ContainerDropDownObjetivo">
                              <div
                              style={{
                                cursor: 'pointer'
                              }}
                                onClick={() => hanlderSelectDropDown(r.titulo)}
                                className="ContainerTitleDropDownObjetivo"
                              >
                                <h3>{r?.titulo}</h3>
                                <img
                                  style={
                                    SelectedEvolucionar === r.titulo
                                      ? { transform: "rotate(180deg)" }
                                      : { transform: "rotate(0deg)" }
                                  }
                                  width={20}
                                  src={ArrowDown}
                                  alt="ArrowDown"
                                />
                              </div>
                              <div
                                className="ContainerTextDropDownObjetivo"
                                style={
                                  SelectedEvolucionar === r?.titulo
                                    ? { display: "block" }
                                    : { display: "none" }
                                }
                              >
                                {r !== undefined ? (
                                  <div>
                                    {Array.isArray(r?.descripcion) ? (
                                      r?.descripcion?.map((rs) => <p className="ContainerPBullet">{rs}</p>)
                                    ) : (
                                      <p className="">{r?.descripcion}</p>
                                    )}
                                  </div>
                                ) : (
                                  <div>2</div>
                                )}
                              </div>
                            </div>
                          );
                        })
                      : ""}
                  </div>
                </div>
              ) : Tipo === "principios-inovacion" ? (
                <div className="ContainerEvolucionarObjetivo">
                  <div className="ContainerSubtitleObjetivo">
                    <p>¿Por qué evolucionar el proceso?</p>
                  </div>
                  <div>
                    {dataP
                      ? dataP?.estructura?.map((r) => {
                          return (
                            <div className="ContainerDropDownObjetivo">
                              <div
                                onClick={() =>
                                  hanlderSelectDropDownInovacion(r.titulo)
                                }
                                className="ContainerTitleDropDownObjetivo"
                              >
                                <h3>{r.titulo}</h3>
                                <img
                                  style={
                                    SelectedInovacion === r.titulo
                                      ? { transform: "rotate(180deg)" }
                                      : { transform: "rotate(0deg)" }
                                  }
                                  width={20}
                                  src={ArrowDown}
                                  alt="ArrowDown"
                                />
                              </div>
                              <div
                                className="ContainerTextDropDownObjetivo"
                                style={
                                  SelectedInovacion === r.titulo
                                    ? { display: "block" }
                                    : { display: "none" }
                                }
                              >
                                <div>
                                  <p>{r?.descripcion}</p>

                                  {r
                                    ? r.children?.map((rc) => {
                                        return (
                                          <div className="ContainerColaboracion">
                                            <h3>{rc?.titulo}</h3>
                                            <p>{rc?.depende_de}</p>
                                            <p>{rc?.descripcion}</p>
                                          </div>
                                        );
                                      })
                                    : ""}
                                </div>
                              </div>
                            </div>
                          );
                        })
                      : ""}
                  </div>
                </div>
              ) : Tipo === "porque-inovar" ? (
                <div className="ContainerEvolucionarObjetivo">
                  <div className="ContainerCopyObjetivo">
                    <p>{dataP?.copy}</p>
                  </div>
                  <div>
  {dataP && dataP.estructura ? (
    dataP.estructura?.map((r) => (
      <div key={r.titulo}> {/* Add a unique key for each mapped element */}
        <div className="ContainerFlexinnovar" style={{ marginTop: 20 }}>
          <img
            width={windowSize?.width <= 837 ? 26 : 35}
            src={`${apiImage}${r?.icono}`}
            alt=""
            srcSet=""
          />
          <p>{r?.titulo}</p>
        </div>
        <div className="ContainerTextDropDownObjetivo" style={{ marginTop: 5 }}>
          <p>{r?.descripcion}</p>
        </div>
      </div>
    ))
  ) : (
    "Loading or empty state" // You can provide an alternative UI when dataP is not available
  )}
</div>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="ContainerImgObjetivo">
            <img src={`${apiImage}${dataP?.imagen}`} alt="" srcset="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Objetivocomponent;
