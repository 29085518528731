// src/context/CosmoContext.js
import React, { createContext, useState, useEffect } from "react";

export const CosmoContext = createContext();

export const CosmoProvider = ({ children }) => {
  const [windowSize, setWindowSize] = useState({
    width: typeof window !== "undefined" ? window.innerWidth : 0,
    height: typeof window !== "undefined" ? window.innerHeight : 0,
  });
  const [Prueba, setPrueba] = useState("MARIO");
  const [ActualRouter, setActualRouter] = useState();
  const [RutaSeleccionada, setRutaSeleccionada] = useState("TODOS");
  const [ColorSeleccionado, setColorSeleccionado] = useState("");
  const [footerSelected, setfooterSelected] = useState(null);
  const [SelectedStep, setSelectedStep] = useState(null);
  const [SelectedRouteSteep, setSelectedRouteSteep] = useState(null);
  const [isBackground, setisBackground] = useState(false);
  const [SelectedRouteMenu, setSelectedRouteMenu] = useState(null);
  const [ShowArrowBack, setShowArrowBack] = useState(false);
  const [ShowArrowNext, setShowArrowNext] = useState(false);
  const [RutadeFooter, setRutadeFooter] = useState(null);
  const [ClicktaskbarButton, setClicktaskbarButton] = useState(false)
  const [DataFases, setDataFases] = useState(null);

  const HanlderFases = (data) => {
    setDataFases(data)
  }
  const HanlderClickTaskbar = (data) => {
    setClicktaskbarButton(data)
  } 


  const HandlerRutafooter = (data) => {
    setRutadeFooter(data);
  };

  const HanlderShowArrowBack = () => {

    setShowArrowBack(true);
  };
  const HanlderHiddenBackarrow = () => {
    setShowArrowBack(false);
  };
  const HanlderShowNextArrow = () => {
    setShowArrowNext(true);
  };
  const HanlderHidenNextArrow = () => {
    setShowArrowNext(false);
  };

  const HanlderSelectRouterMenu = (data) => {
    setSelectedRouteMenu(data);
  };

  const HandlerIsBackground = (data) => {
    setisBackground(data);
  };

  const HanlderSelectRouteSteep = (data) => {
    setSelectedRouteSteep(data);
  };
  const HanlderSelectedStep = (data) => {
    setSelectedStep(data);
  };

  const HanlderSelectedFooter = (data) => {
    setfooterSelected(data);
  };

  const HanlderSelectRouter = (data, color) => {
    setRutaSeleccionada(data);
    setColorSeleccionado(color);
  };

  const HandlerRoute = (data) => {
    setActualRouter(data);
  };
  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const authContextValue = {
    Prueba,
    setPrueba,
    HandlerRoute,
    ActualRouter,
    windowSize,
    HanlderSelectRouter,
    RutaSeleccionada,
    ColorSeleccionado,
    footerSelected,
    HanlderSelectedFooter,
    SelectedStep,
    HanlderSelectedStep,
    SelectedRouteSteep,
    HanlderSelectRouteSteep,
    isBackground,
    HandlerIsBackground,
    SelectedRouteMenu,
    HanlderSelectRouterMenu,
    ShowArrowBack,
    ShowArrowNext,
    HanlderShowArrowBack,
    HanlderHiddenBackarrow,
    HanlderShowNextArrow,
    HanlderHidenNextArrow,
    RutadeFooter,
    HandlerRutafooter,
    ClicktaskbarButton,
HanlderClickTaskbar,
DataFases,
HanlderFases
  };

  return (
    <CosmoContext.Provider value={authContextValue}>
      {children}
    </CosmoContext.Provider>
  );
};
