import React,{useContext} from 'react';
import Lottie from 'lottie-react';
import CosmoAnimation from '../../assets/amimation/cosmos.json'
import { CosmoContext } from '../../context/CosmoContext';
import {ContainerSplash} from './SpalshStyles'
const SplashScreen = () => {
    const {Prueba,setPrueba} = useContext(CosmoContext)
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: CosmoAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <ContainerSplash>
      <div className='containerSpalsh'>
        <Lottie options={defaultOptions}/>
      </div>
    </ContainerSplash>
  );
};

export default SplashScreen;
