import React, { useState, useEffect } from "react";
import ReactModal from "react-modal";
import "./prueba.css";
import { ModalLayout, ModalManualLayout } from "./ModalStyles";
import CloseImg from "../../../assets/images/menu/CloseSvg.svg";
import  SitemapMovil from "../../../assets/images/Sitemapmobile.svg"
import  SitemapDesk from "../../../assets/images/Sitemapdesk.svg"
// CMS

const ModalSitemapPrime = ({ modal, closemodal, dataRoute, Tipo, windowSize,children }) => {

  const [modalStyles, setModalStyles] = useState({
    content: {
      width: "100%",
      left: "0%",
      height: "100vh",
      top: "0%",
      transform: "translate(-0%, -0%) translateX(-100%)",
      transition: "transform 0.3s ease-in-out",
      borderTopRightRadius: "25px",
      overflow: "scroll",
      background: "none",
    },
    overlay: {
      // backgroundColor: "rgba(0, 0, 0, 0.5)",
      opacity: 0,
      transition: "opacity 0.3s ease-in-out",
    },
  });

  useEffect(() => {
    if (modal) {
      document.body.style.overflow = "hidden";
      setModalStyles((prevStyles) => ({
        ...prevStyles,
        content: {
          ...prevStyles.content,
          transform: "translate(-0%, -0%) translateX(0%)",
        },
        overlay: {
          ...prevStyles.overlay,
          opacity: 1,
          zIndex: 99,
        },
      }));
    } else {
      document.body.style.overflow = "auto";
      setModalStyles((prevStyles) => ({
        ...prevStyles,
        content: {
          ...prevStyles.content,
          transform: "translate(-0%, -0%) translateX(-100%)",
        },
      }));
    }
  }, [modal]);



  return (
    <ReactModal
      isOpen={modal}
      contentLabel="onRequestClose Example"
      onRequestClose={closemodal}
      shouldCloseOnOverlayClick={false}
      style={modalStyles}
      ariaHideApp={false}
    >
      <div 
      style={{height: '100vh'}}
      className={modal ? "blur-background" : ""}></div>
      <ModalLayout>
        <ModalManualLayout
        style={windowSize.width <= 837 ? 
          {marginTop: '10%'}
        :
          {marginTop: '0%'}
      }
        >
          <div
            className={
              windowSize.width <= 837
                ? "CloseBotonModalMobil"
                : "CloseBotonModalDesk"
            }
            onClick={() => closemodal()}
          >
            <img src={CloseImg} alt="" srcset="" />
          </div>
          <div className="ContainerTitle">
            <h3>Macroproceso de gestión de iniciativas e innovación</h3>
            <div className="LineGradientModalTitle"></div>
          </div>
         
          <div className="BodyInfomodal">
                <div
                style={{display: 'flex', justifyContent: 'center'}}
                >
                    {
                        windowSize.width <= 837 ?
                            <>
                                <img 
                                width={'85%'}
                                src={SitemapMovil} alt="" srcset="" />    
                            </>
                        :
                        <>
                         <img src={SitemapDesk} alt="" srcset="" /> 
                        </>
                    }
                </div>
          </div>
        </ModalManualLayout>
      </ModalLayout>
    </ReactModal>
  );
};

export default ModalSitemapPrime