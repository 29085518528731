import React, { useContext, useEffect } from "react";
import { CosmoContext } from "../../context/CosmoContext";
import { useLocation } from "react-router-dom";
import FaseComponent from "./FaseComponent";
import SteeperComponent from "./SteeperComponent";
import GateComponent from "./GateComponent";
import Pointcomponent from "./PointComponent";
import Arrows from "../Arrows/Arrows";

const Stepper = ({
  dataMarco,
  windowSize,
  footerSelected,
  SelectedStep,
  dataFases,
  dataPasos,
  HandlerIsBackground,
}) => {
  const location = useLocation();
  const apiUrl = process.env.REACT_APP_BASE_URL;
  const apiImage = process.env.REACT_APP_BASE_URLIMAGE;
  const { HandlerRoute, ActualRouter } = useContext(CosmoContext);

  useEffect(() => {
    HandlerRoute(location.pathname);
  }, [ActualRouter]);

  return (
    <div style={{ 
      display: 'flex',
      flexDirection: 'column',
      flex: 1
    }}>
      {
       SelectedStep ?(
        SelectedStep.tipo === "paso" ? (
          <div
            style={{
              marginBottom: 0,
              position: "relative",
            }}
          >
            <SteeperComponent
              apiImage={apiImage}
              dataPaso={dataPasos}
              footerSelected={footerSelected}
              ActualRouter={ActualRouter}
              HandlerIsBackground={HandlerIsBackground}
              HandlerRoute={HandlerRoute}
              windowSize={windowSize}
            />
            <Arrows
              Tipo={"Pasos"}
             ></Arrows>
          </div>
        ) : SelectedStep.tipo === "gate" ? (
          <div
            style={{
              marginBottom: 30,
              position: "relative",
            }}
          >
            <GateComponent
              apiImage={apiImage}
              dataGate={dataPasos}
              footerSelected={footerSelected}
              HandlerIsBackground={HandlerIsBackground}
              HandlerRoute={HandlerRoute}
              windowSize={windowSize}
            ></GateComponent>
            {/* <Arrows
               Tipo={"Gate"}
               ></Arrows> */}
          </div>
        ) : (
          <div>
            <Pointcomponent
              apiImage={apiImage}
              dataPoint={dataPasos}
              footerSelected={footerSelected}
              ActualRouter={ActualRouter}
              HandlerIsBackground={HandlerIsBackground}
              HandlerRoute={HandlerRoute}
            ></Pointcomponent>
            {/* <Arrows></Arrows> */}
          </div>
        )
      ) : (
        <div>
          <FaseComponent
            apiImage={apiImage}
            dataFases={dataFases}
            footerSelected={footerSelected}
            HandlerIsBackground={HandlerIsBackground}
            HandlerRoute={HandlerRoute}
          ></FaseComponent>
          {/* <Arrows></Arrows> */}
        </div>
      )
      }
    </div>
  )
};

export default Stepper;
