import React from 'react'
import styled from 'styled-components';



const SiguientesIcon = () => {
    
  return (
    <div className='ContainerArrowFlex'>
<svg xmlns="http://www.w3.org/2000/svg" width="11.618" height="11.706" viewBox="0 0 11.618 11.706">
      <g id="Group_20880" data-name="Group 20880" transform="translate(-2425.992 -1217.844)">
        <path id="Path_170307"   data-name="Path 170307" d="M.617,358.283a.785.785,0,0,1-.536-.408.716.716,0,0,1,.072-.775,1.321,1.321,0,0,1,.129-.145l4.375-4.438c.026-.027.051-.055.082-.088-.031-.033-.058-.064-.087-.093L.262,347.881a.747.747,0,0,1-.156-.946.723.723,0,0,1,1.069-.2,1.457,1.457,0,0,1,.128.114l4.937,5.008a.761.761,0,0,1,.159.948.956.956,0,0,1-.155.2q-2.471,2.51-4.943,5.019a1.025,1.025,0,0,1-.456.267" transform="translate(2425.992 871.267)" fill="#3f348b"/>
        <path id="Path_170308"   data-name="Path 170308" d="M28.165,358.284a.772.772,0,0,1-.549-.424.717.717,0,0,1,.091-.773,1.467,1.467,0,0,1,.123-.135l4.335-4.4c.037-.038.081-.07.132-.115l-.111-.115-4.374-4.437a.749.749,0,0,1-.169-.944.724.724,0,0,1,1.084-.207,1.532,1.532,0,0,1,.118.108l4.944,5.015a.76.76,0,0,1,.155.939.954.954,0,0,1-.155.2q-2.47,2.509-4.942,5.017a1.038,1.038,0,0,1-.453.271" transform="translate(2403.567 871.266)" fill="#3f348b"/>
      </g>
    </svg>
    </div>
    

  )
}

export default SiguientesIcon