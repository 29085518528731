import styled from "styled-components"
import { motion } from "framer-motion";
import GlobalStyles from "../../../theme/GlobalStyles";

export const ButtonStyle = styled.button`
    width: 340px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
    border: none;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 28px;
    margin-top: 30px;
    cursor: pointer;
    .ContainerTextLogin{
        font-family: 'Montserrat-Bold', sans-serif;
        text-align: center;
        color: #403F3F;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 0.9rem;
    }

  // Mobil Frist
  @media screen and (min-width: 200px){
    .ButtonLogin{
        width: 30px !important; 
        height: 30px;
        margin-right: 20px;
        margin-left: 40px;
    }

  }
  @media screen and (min-width: 720px){
    
  }
  @media screen and (min-width: 900px){
   
  }
  @media screen and (min-width: 1200px){

  }
`
;