import React,{useEffect} from "react";
import { FasesContainer } from "./FasesStyle";
import GeteIcon from "../../assets/images/gateicon.svg";
import useModals from "../../utils/components/modals/useModals";
import ModalImage from "../../utils/components/modals/ModalImage";
const GateComponent = ({ apiImage, dataGate, footerSelected,HandlerIsBackground,windowSize }) => {
  const {ModalImages,
    OpenModalImages,
    CloseModalImages} =useModals()
  
  useEffect(() => {
    HandlerIsBackground(true)
  }, [])
  
  const formattedCriterio = dataGate?.criterios_de_decision?.replace(
    /\n/g,
    "</p><p>"
  );

 
  const SalidasText = formattedCriterio
    ?.split("</p>")
    .map((paragraph, index) => (
      <p
        className="TextEntradasFases"
        key={index}
        dangerouslySetInnerHTML={{ __html: paragraph }}
      />
    ));

  const formattedAvances = dataGate?.decision?.replace(/\n/g, "</p><p>");


 
  const EntradasText = formattedAvances
    ?.split("</p>")
    .map((paragraph, index) => (
      <p
        className="TextEntradasFases"
        key={index}
        dangerouslySetInnerHTML={{ __html: paragraph }}
      />
    ));

    console.log(dataGate,"DATOS")

  return (
    <FasesContainer
      tipo={dataGate?.tipo}
    >
      <div
        className="ContainerHeaderFases"
        style={{
          backgroundImage: `url(${apiImage}${dataGate?.imagen})`,
        }}
      >
        <div className="ContainerFloatHeaderFase">
          <div
            className="ContainerTitleFase"
            style={{ background: dataGate?.colores[0].color }}
          >
            <p>
              {dataGate?.numeropaso == "01"
                ? `Gate ${dataGate?.numeropaso}`
                :
                dataGate?.numeropaso == "02"
                ? `Gate ${dataGate?.numeropaso}`
                :
                dataGate?.numeropaso == "03"
                ? `Gate ${dataGate?.numeropaso}`
                :
                dataGate?.numeropaso == "04"
                ? `Gate ${dataGate?.numeropaso}`
                : ""}
            </p>
          </div>
          <div className="ContainerTittleNameFases">
            <div className="NameFaseContainer">
              <div className="ContainerImgNameFase">
                <img
                  width={30}
                  src={GeteIcon}
                  alt=""
                  srcset=""
                />
              </div>
              <div className="ContainerNameFases">
                <p>{dataGate?.titulo}</p>
              </div>
            </div>
            <div
              style={{ background: dataGate?.colores[0].color }}
              className="lineNameFase"
            ></div>
          </div>
        </div>
      </div>
      <div>
        <div className="ContainerFlexFasesTop">
          <div className="ContainerFlexFasesLeft">
            <div className="ContainerDescriptionName">
              <h2>Descripción</h2>
              <p>{dataGate?.descripcion}</p>
            </div>
            <div className="LineBottonFases"></div>
            <div className="ContainerCheksFlex">
              <div className="ContainerChekLeft">
                <div className="ContainerChekTitle">
                  <div className="ContainerStepStep">
                    <p>0.1</p>
                  </div>
                  <h3 style={{ marginLeft: 5 }}>CRITERIOS DE DECISIÓN</h3>
                </div>
                <div className="ContainerTextEntradasFases">
                  <p>{SalidasText}</p>
                </div>
              </div>
              <div className="ContainerChekRight">
                <div className="ContainerChekTitle">
                  <div className="ContainerStepStep">
                    <p>0.2</p>
                  </div>
                  <h3 style={{ marginLeft: 5 }}>AVANCES</h3>
                </div>
                <div className="ContainerTextEntradasFases">
                  <p>{EntradasText}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="ContainerFlexFasesRight">
            <div className="ContainerFloatObjetivo">
              <div 
              style={{marginBottom: 10}}
              className="ContianerObjetivoName">
                <p>Objetivo</p>
                {/* <div className="lineObjetivoFases"></div> */}
              </div>
              <div className="ContainerTextObjetivoFases">
                <p>{dataGate?.objetivo}</p>
              </div>

              <div className="ContianerObjetivoName" style={{ marginTop: 10 }}>
                <p>Entregables</p>
                <div className="lineObjetivoFases"></div>
              </div>
              <div className="ContainerTextObjetivoFases">
                <p>{dataGate?.entregables}</p>
              </div>
              <div 
              onClick={() => OpenModalImages()}
              className="ButtonFase">
                <a>Ver info</a>
               </div>
              <div className="ContianerObjetivoName" style={{ marginTop: 10 }}>
                <p>Actores</p>
                <div className="lineObjetivoFases"></div>
              </div>
              <div className="ContainerTextObjetivoFases">
                <p>{dataGate?.actores}</p>

               <div className="ButtonFase">
                <a href={`${dataGate?.formulario}`}>Llenar formulario</a>
               </div>
              </div>
            </div>
          </div>
        </div>
        <ModalImage
       modal={ModalImages}
       closemodal={CloseModalImages}
       Tipo="Manual"
       windowSize={windowSize}
      >
        <div
        style={windowSize?.width <= 837 ? {display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems:'center', marginTop: '50px'}  
        :{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems:'center'}}
        >
          <img 
          width={windowSize?.width <= 837 ? '696px' :'100%'}
          height={windowSize?.width <= 837 ? '290px' :'100%'}
          src={`${apiImage}${dataGate?.imagen_entregables}`} alt="" srcset="" />
        </div>
      </ModalImage>
      </div>
    </FasesContainer>
  );
};

export default GateComponent;
