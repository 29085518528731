import React, { useState, useEffect, useContext } from "react";
import { TaskBarIcon } from "./TaskBarStyles";

import RutaIcon from "../../assets/images/taskbar/Rutas.svg";
import MarcoIcon from "../../assets/images/taskbar/Macro.svg";
import PoliticasIcon from "../../assets/images/taskbar/Politicas.svg";
import ManualIcon from "../../assets/images/taskbar/UserManual.svg";
import MartizIcon from "../../assets/images/taskbar/Matriz.svg";
import SiteMapIcon from "../../assets/images/taskbar/Sitemap.svg";
import { useNavigate } from "react-router-dom";
import { CosmoContext } from "../../context/CosmoContext";
const TaskBarComponent = ({
  openModalMarco,
  openModalManual,
  openModalMatriz,
  windowSize,
  openModalSite,
  DataHeader,
  RutaFooter,
  RutaSeleccionada,
  HanlderSelectRouter,
  HanlderSelectedStep,
  ActualRouter,
  RutadeFooter,
  footerSelected,
  HanlderSelectedFooter,
}) => {
  const navigate = useNavigate();

  const [containerClick, setContainerClick] = useState(false);
  const { ClicktaskbarButton, HanlderClickTaskbar } = useContext(CosmoContext);

  const [showSubMenu, setShowSubMenu] = useState(false); // Estado para controlar el submenú

  const toggleSubMenu = () => {
    setShowSubMenu(!showSubMenu); // Alternar el submenú
  };

  useEffect(() => {
    const handleEffect = async () => {
      if (ClicktaskbarButton === true) {
        // Realiza acciones adicionales según sea necesario
        setTimeout(() => {
          if (RutaFooter[0]?.nombre === "DEFINICIÓN ESTRATÉGICA") {
            HanlderClickTaskbar(false);
            HanlderSelectedStep(null);
            HanlderSelectedFooter("DEFINICIÓN ESTRATÉGICA");
            navigate("/definicion-estrategica", { replace: true });
          } else if (
            RutadeFooter[0]?.nombre === "IDEACIÓN Y PLAN DE NEGOCIOS"
          ) {
            HanlderClickTaskbar(false);
            HanlderSelectedStep(null);
            HanlderSelectedFooter("IDEACIÓN Y PLAN DE NEGOCIOS");
            navigate("/ideacion", { replace: true });
          } else if (RutadeFooter[0]?.nombre === "DESARROLLO") {
            HanlderClickTaskbar(false);
            HanlderSelectedStep(null);
            HanlderSelectedFooter("DESARROLLO");
            navigate("/desarrollo", { replace: true });
          } else if (RutadeFooter[0]?.nombre === "FABRICACIÓN") {
            HanlderClickTaskbar(false);
            HanlderSelectedStep(null);
            HanlderSelectedFooter("FABRICACIÓN");
            navigate("/fabricacion", { replace: true });
          } else if (RutadeFooter[0]?.nombre === "SEGUIMIENTO") {
            HanlderClickTaskbar(false);
            HanlderSelectedStep(null);
            HanlderSelectedFooter("SEGUIMIENTO");
            navigate("/seguimiento", { replace: true });
          }
        }, 1000);
      }
    };

    handleEffect();
  }, [ClicktaskbarButton, RutadeFooter, HanlderClickTaskbar]);

  const HandlerIniciar = () => {
    if (RutaFooter[0]?.nombre === "DEFINICIÓN ESTRATÉGICA") {
      HanlderClickTaskbar(false);
      HanlderSelectedStep(null);
      navigate("/definicion-estrategica", { replace: true });
    } else if (RutadeFooter[0]?.nombre === "IDEACIÓN Y PLAN DE NEGOCIOS") {
      HanlderClickTaskbar(false);
      HanlderSelectedStep(null);
      navigate("/ideacion", { replace: true });
    } else if (RutadeFooter[0]?.nombre === "DESARROLLO") {
      HanlderClickTaskbar(false);
      HanlderSelectedStep(null);
      navigate("/desarrollo", { replace: true });
    } else if (RutadeFooter[0]?.nombre === "FABRICACIÓN") {
      HanlderClickTaskbar(false);
      HanlderSelectedStep(null);
      navigate("/fabricacion", { replace: true });
    } else if (RutadeFooter[0]?.nombre === "SEGUIMIENTO") {
      HanlderClickTaskbar(false);
      HanlderSelectedStep(null);
      navigate("/seguimiento", { replace: true });
    }
  };

  const handlerGo = async (datas) => {
    HanlderClickTaskbar(true);
    HanlderSelectRouter(datas?.nombre, datas?.color);

    HanlderSelectedStep(null);
  };

  const iniciativas = DataHeader || [];
  const order = [
    "TODAS",
    "INICIATIVA DISRUPTIVA",
    "COMBO",
    "RÉPLICA",
    "EXHIBICIÓN",
    "ARTES",
    //"IMPORTACIONES",
    "INICIATIVA INCREMENTAL",
  ];
  const orderMovil = [
    "TODAS",
    "ARTES",
    "COMBO",
    "EXHIBICIÓN",
    "RÉPLICA",
    "INICIATIVA DISRUPTIVA",
    "INICIATIVA INCREMENTAL",
    //"IMPORTACIONES",
  ];

  const sortedIniciativas = order
    .map((nombre) =>
      iniciativas.find(
        (iniciativa) => iniciativa && iniciativa.nombre === nombre
      )
    )
    .filter(Boolean);

  const [iniciativasList, setIniciativasList] = useState(sortedIniciativas);

  const sortedIniciativasMovil = orderMovil
    .map((nombre) =>
      iniciativas.find(
        (iniciativa) => iniciativa && iniciativa.nombre === nombre
      )
    )
    .filter(Boolean);

  const [iniciativasListMovil, setIniciativasListMovil] = useState(
    sortedIniciativasMovil
  );
  const [ShowOptios, setShowOptios] = useState(false);

  const HanlderShowOptions = () => {
    if (ShowOptios === true) {
      setShowOptios(false);
    } else {
      setShowOptios(true);
    }
  };

  return (
    <TaskBarIcon
      style={
        windowSize.width <= 837 ? { display: "none" } : { display: "block" }
      }
    >
      <div
        onClick={() => HanlderShowOptions()}
        className="ContainerIconTaskBar"
      >
        <img src={RutaIcon} width={30} alt="" srcSet="" />
        <div className="tooltip">
          <p>Rutas</p>
        </div>
      </div>
      <div onClick={() => openModalMarco()} className="ContainerIconTaskBar">
        <img src={MarcoIcon} width={35} alt="" srcSet="" />
        <div className="tooltip">
          <p>Macro</p>
        </div>
      </div>
      <div onClick={() => openModalManual()} className="ContainerIconTaskBar">
        <img src={ManualIcon} width={30} alt="" srcSet="" />
        <div className="tooltip">
          <p>Manual</p>
        </div>
      </div>
      <div onClick={() => openModalMatriz()} className="ContainerIconTaskBar">
        <img
          src={MartizIcon}
          width={40}
          style={{ paddingTop: 15, paddingBottom: 10 }}
          alt=""
          srcSet=""
        />
        <div className="tooltip">
          <p>Matriz</p>
        </div>
      </div>
      <div className="ContainerIconTaskBar">
        <img src={PoliticasIcon} width={30} alt="" srcSet="" />
        <div className="tooltip">
          <p>Políticas</p>
        </div>
      </div>
      <div onClick={() => openModalSite()} className="ContainerIconTaskBar">
        <img src={SiteMapIcon} width={35} alt="" srcSet="" />
        <div className="tooltip">
          <p>Mapa</p>
        </div>
      </div>
      <div
        style={ShowOptios === true ? { background: "" } : { display: "none" }}
        className="ContainerIconTaskBar containerFloatDD"
      >
        <p
          onClick={() =>
            handlerGo({
              id: 0,
              nombre: "TODOS",
              color: "",
            })
          }
          className="TopBarTodos"
        >
          TODOS
        </p>
        
        {iniciativasList.map((iniciativa) => (
          <div key={iniciativa?.id}>
            <p
              onClick={() => {
                if (iniciativa?.nombre === "INICIATIVA INCREMENTAL") {
                  toggleSubMenu(); // Lógica para el submenú
                } else {
                  handlerGo(iniciativa);
                }
              }}
              className="TopBarItem"
              style={{ color: iniciativa?.color, fontWeight: "bold" }}
            >
              {iniciativa?.nombre}{" "}
              {iniciativa?.nombre === "INICIATIVA INCREMENTAL" && (
                <span>{showSubMenu ? "▲" : "▼"}</span> // Flecha que cambia
              )}
            </p>

            {showSubMenu && iniciativa?.nombre === "INICIATIVA INCREMENTAL" && (
              <div className="SubMenu">
                {/* Aquí se renderizan las opciones del submenú */}
                <p className="SubMenuItem">Extensión de fórmula</p>
                <p className="SubMenuItem">Extensión de empaque</p>
                <p className="SubMenuItem">Pilón</p>
              </div>
            )}
          </div>
        ))}


        <img
          onClick={() => HanlderShowOptions()}
          src={RutaIcon}
          width={30}
          alt=""
          srcSet=""
        />
      </div>
    </TaskBarIcon>
  );
};

export default TaskBarComponent;
