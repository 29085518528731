import logo from "./logo.svg";
import "./App.css";
import { ThemeProvider } from "styled-components";
import SplashScreen from "./components/splashScreen/SplashScreen";
import GlobalStyles from "./theme/GlobalStyles";
import { CosmoProvider } from "./context/CosmoContext";
import Login from "./pages/Login";
import { AuthProvider } from "./context/AuthContext";
import AppRouter from "./router/Router";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function App() {
    const theme = {
        // Puedes agregar más variables de tema aquí si es necesario
    };
    return (
        <ThemeProvider theme={theme}>
            <AuthProvider>
                <CosmoProvider>


                    <div className="App">
                        <AppRouter></AppRouter>
                    </div>
                </CosmoProvider>
            </AuthProvider>

            <GlobalStyles />
        </ThemeProvider>
    );
}

export default App;
