import React, { useState, useEffect, useRef  } from "react";
import ArrowDown from "../../assets/images/menu/flechaabajo.svg";
import Slider from "react-slick";

import {
  ContainerTopBarDesk,
  ContainerTopBarMovil,
} from "../home/TopBarStyles";
import ModalImage from "../../utils/components/modals/ModalImage";
import useModals from "../../utils/components/modals/useModals";
const ProyectoComponent = ({

  DataProyectos,
  windowSize,
  apiImage,
  buscarProyecto,
  HanlderChangueProyecto,
  DataProyecto,
  HanlderSelectRouterMenu,
  OpenModalMarcoPrime,
}) => {
  const [ShowMenuProyecto, setShowMenuProyecto] = useState(false);
  const [ProyectoSeleccionado, setProyectoSeleccionado] = useState("ARTES");
  const [ColorSeleccionado, setColorSeleccionado] = useState("#FFC200");
  const { ModalImages, OpenModalImages, CloseModalImages } = useModals();
  const [showSubMenu, setShowSubMenu] = useState(false); // Estado para el submenú de INICIATIVA INCREMENTAL

  useEffect(() => {
    if (DataProyecto === undefined) {
      HanlderSelectRouterMenu();
    }
  }, [DataProyecto]);

  const sliderRef = useRef(null);
  useEffect(() => {
    if (sliderRef.current && DataProyecto?.casosExito?.length > 0) {
      sliderRef.current.slickGoTo(0); // Reiniciar el slider cuando cambie el contenido
    }
  }, [DataProyecto?.casosExito]);

  const etiquetasString = DataProyecto?.etiquetas?.length > 0 
  ? DataProyecto.etiquetas.join(" | ") 
  : DataProyecto?.titulo;

  const settings = {
    dots: true,
    fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const HanlderCambioMenu = (data) => {
    if (data.titulo === "INICIATIVA INCREMENTAL") {
      setShowSubMenu(!showSubMenu); // Alternar el submenú cuando se selecciona INICIATIVA INCREMENTAL
    } else {
      setProyectoSeleccionado(data.titulo);
      setColorSeleccionado(getColorByTitle(data.titulo)); // Cambiar el color basado en el título
      setShowMenuProyecto(false);
      setShowSubMenu(false); // Ocultar el submenú si selecciona otro
    }
  };

  const HanlderCambioMenuSubMenu = (data) => {
      setProyectoSeleccionado(data.titulo);
      setColorSeleccionado(getColorByTitleSubMenu(data.titulo)); // Cambiar el color basado en el título
      setShowMenuProyecto(false);
      setShowSubMenu(false); // Ocultar el submenú si selecciona otro
  }

  const getColorByTitle = (titulo) => {
    switch (titulo) {
      case "ARTES":
        return "#FFC200";
      case "COMBO":
        return "#E2006C";
      case "EXHIBICIÓN":
        return "#8F00AD";
      case "RÉPLICA":
        return "#21CEA6";
      case "INICIATIVA DISRUPTIVA":
        return "#0080FF";
      case "INICIATIVA INCREMENTAL":
        return "#7AD800";
      case "IMPORTACIONES":
        return "#00BF1B";
      default:
        return "";
    }
  };

  const getColorByTitleSubMenu = (id) => {
    switch (id) {
      case 8:
        return "#7AD800";
      case 9:
        return "#7AD800";
      case 10:
        return "#7AD800";
      default:
        return "#7AD800";
    }
  };

  const HanlderShowMenu = () => {
    if (ShowMenuProyecto === true) {
      setShowMenuProyecto(false);
    } else {
      setShowMenuProyecto(true);
    }
  };

  // Filtrar los elementos por titulo o id del JSON
  const subMenuItems = DataProyectos?.filter(
    (item) => item.id === 8 || item.id === 9 || item.id === 10 || 
    item.titulo === "Extensión de empaque" || item.titulo === "Extensión de Fórmula" || item.titulo === "Pilón"
  );


  useEffect(() => {
    const objetoSeleccionado = buscarProyecto(ProyectoSeleccionado);
    HanlderChangueProyecto(objetoSeleccionado);
  }, [ProyectoSeleccionado]);

  return (
    <div 
    style={{
      display: 'flex',
      flex: 1,
      flexDirection: 'column'
    }}
    className="ContainerObjetivo">
      {windowSize?.width <= 837 ? (
        <div>
          <ContainerTopBarMovil>
            <div
              onClick={() => HanlderShowMenu()}
              className="ContainerArrowDownItem"
            >
              <div
                className="ItemMenuTopBarMovil"
                style={{ marginBottom: -10, paddingBottom: 0 }}
              >
                <p style={{ color: ColorSeleccionado }}>
                  {ProyectoSeleccionado}
                </p>
              </div>
              <div>
                <img src={ArrowDown} alt="ArrowDown" />
              </div>
            </div>

            <div
              style={
                ShowMenuProyecto === true
                  ? { display: "block" }
                  : { display: "none" }
              }
              className="FloatMenuMovilHome"
            >
              <div className="ItemMenuTopBarMovil">
                {DataProyectos
                ?.filter((r) => 
                  (r.titulo !== "Extensión de empaque" && r.titulo !== "Extensión de Fórmula" && r.titulo !== "Pilón") &&
                  (r.id !== 8 && r.id !== 9 && r.id !== 10) &&
                  (r.titulo !== "IMPORTACIONES")
                )
                .map((r) => {
                  return (
                    <div>
                      <div 
                        onClick={() => HanlderCambioMenu(r)}
                        style={{ cursor: "pointer" }}
                        className="ContainerIconMenuProyectos"
                      >

                        <img width={20} height={20} src={`${apiImage}${r?.icono}`} alt="" srcset="" />
                        <p style={{ color: getColorByTitle(r?.titulo) }}>
                          {r?.titulo}
                          {r?.titulo === "INICIATIVA INCREMENTAL" && (
                            <span>{showSubMenu ? "▲" : "▼"}</span>
                          )}
                        </p>

                      </div>
                      
                      <div>
                  {/* Submenú para INICIATIVA INCREMENTAL */}
                  {showSubMenu && r?.titulo === "INICIATIVA INCREMENTAL" && (
                    <div className="SubMenu_tipo_proyectos_movil">
                      {subMenuItems.map((subItem) => (
                        <p
                          key={subItem.id}
                          className="SubMenuItem_tipo_proyectos"
                          onClick={() => {
                            HanlderCambioMenuSubMenu(subItem);
                            setShowSubMenu(false); // Cierra el submenú después de seleccionar
                          }}
                        >
                          {subItem.titulo}
                        </p>
                      ))}
                    </div>
                  )}
                </div>
                      
                      

                    </div>
                  );
                })}
              </div>
            </div>
          </ContainerTopBarMovil>
          <div className="ContainerTitleproyect">
            <h2>Tipos de proyecto</h2>
            <div className="LineProyect"></div>
          </div>
        </div>
      ) : (
        <div>
          <div className="ContainerTitleproyect">
            <h2>Tipos de proyecto</h2>
            <div className="LineProyect"></div>
          </div>
          <ContainerTopBarDesk>
            {DataProyectos
            ?.filter((r) => 
              (r.titulo !== "Extensión de empaque" && r.titulo !== "Extensión de Fórmula" && r.titulo !== "Pilón") &&
              (r.id !== 8 && r.id !== 9 && r.id !== 10) &&
              (r.titulo !== "IMPORTACIONES")
            )
            .map((r) => (
              <div>
                <div  key={r.id}
                      onClick={() => HanlderCambioMenu(r)}
                      style={
                        ProyectoSeleccionado === r?.titulo
                          ? { opacity: 1, cursor: "pointer" }
                          : { opacity: 0.5, cursor: "pointer" }
                      }
                      className="ContainerIconMenuProyectos">

                  <img width={20} height={20} src={`${apiImage}${r?.icono}`} alt="" />
                  <p style={{ color: getColorByTitle(r?.titulo) }}>
                    {r?.titulo}
                    {r?.titulo === "INICIATIVA INCREMENTAL" && (
                      <span>{showSubMenu ? "▲" : "▼"}</span>
                    )}
                  </p>
                </div>

                <div>
                  {/* Submenú para INICIATIVA INCREMENTAL */}
                  {showSubMenu && r?.titulo === "INICIATIVA INCREMENTAL" && (
                    <div className="SubMenu_tipo_proyectos">
                      {subMenuItems.map((subItem) => (
                        <p
                          key={subItem.id}
                          className="SubMenuItem_tipo_proyectos"
                          onClick={() => {
                            HanlderCambioMenuSubMenu(subItem);
                            setShowSubMenu(false); // Cierra el submenú después de seleccionar
                          }}
                        >
                          {subItem.titulo}
                        </p>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            ))}
          </ContainerTopBarDesk>
        </div>
      )}

      <div 
      
      className="ContainerFlexProyectos"
      >
        <div className="ContainerProyectosLeft">
          <div
            style={
              ProyectoSeleccionado === "ARTES"
                ? {
                    background: ColorSeleccionado,
                    width: windowSize?.width <= 837 ? "90%" : "40%",
                  }
                : ProyectoSeleccionado === "COMBO"
                ? {
                    background: ColorSeleccionado,
                    width: windowSize?.width <= 837 ? "90%" : "40%",
                  }
                : ProyectoSeleccionado === "EXHIBICIÓN"
                ? {
                    background: ColorSeleccionado,
                    width: windowSize?.width <= 837 ? "90%" : "50%",
                  }
                : ProyectoSeleccionado === "INICIATIVA DISRUPTIVA"
                ? {
                    background: ColorSeleccionado,
                    width: windowSize?.width <= 837 ? "90%" : "50%",
                  }
                : ProyectoSeleccionado === "INICIATIVA INCREMENTAL"
                ? {
                    background: ColorSeleccionado,
                    width: windowSize?.width <= 837 ? "90%" : "60%",
                  }
                : ProyectoSeleccionado === "IMPORTACIONES"
                ? {
                    background: ColorSeleccionado,
                    width: windowSize?.width <= 837 ? "90%" : "40%",
                  }
                : ProyectoSeleccionado === "Extensión de empaque" || ProyectoSeleccionado === "Extensión de Fórmula" 
                || ProyectoSeleccionado === "Pilón"
                ? {
                  background: ColorSeleccionado,
                  width: windowSize?.width <= 837 ? "90%" : "60%",
                }
                
                : { background: "" }
            }
            className="EtiquetaProyectos"
          >
            <p>{etiquetasString}</p>
          </div>
          <div 
          style={etiquetasString === "" ? {marginTop: -20} : {background: 'transparent'}}
          className="InfoTextProyectos">
            <p>{DataProyecto?.descripcion}</p>
          </div>

          <div>
            <div
              style={
                windowSize?.width <= 837
                  ? { display: "none" }
                  : { display: "block" }
              }
              onClick={() => OpenModalImages()}
              className="ButtonFase"
            >
              <p>VER PROCESO</p>
            </div>
          </div>
        </div>
        <div className="ContainerFlexProyectosRiht">
          <div className="ContainerNombreExito">
            <p>Casos de éxito</p>
          </div>

          <div className="ContainerProyectosExito" style={{
            borderRadius: 10
          }}>



            <Slider
              ref={sliderRef}
              {...settings}
              style={{
                width: 400, // Ancho fijo
                height: windowSize?.width <= 837 ? 226  : 282,
                maxWidth: "100%",
                maxHeight: "100%",
                borderRadius: '10px !important'
                 // Asegura que el carrusel no sea más ancho que su contenedor
              }}
            >
              
              {DataProyecto?.casosExito?.length > 0 && (
                DataProyecto.casosExito.map((ce, index) => (
                  <div key={index} className="SliderItem">
                    <img
                      style={{
                        width: "100%",
                        height: "auto", // Permite que la altura se ajuste automáticamente
                        objectFit: "contain",
                        borderRadius: 10,
                      }}
                      src={`${apiImage}${ce?.imagen}`}
                      alt={`Casos de éxito ${index + 1}`}
                    />
                  </div>
                ))
              )}
            </Slider>

          </div>
          <div
            style={
              windowSize?.width <= 837
                ? { display: "block", marginBottom: 30, marginTop: 30 }
                : { display: "none" }
            }
            onClick={() => OpenModalImages()}
            className="ButtonFase"
          >
            <p>VER PROCESO</p>
          </div>
        </div>
      </div>
      <ModalImage
        modal={ModalImages}
        closemodal={CloseModalImages}
        Tipo="Manual"
        windowSize={windowSize}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            width={windowSize?.width <= 837 ? "100%" : "60%"}
            src={
              windowSize?.width <= 837
                ? `${apiImage}${DataProyecto?.proceso_mobile}`
                : `${apiImage}${DataProyecto?.proceso}`
            }
            alt=""
            srcset=""
          />
        </div>
      </ModalImage>
    </div>
  );
};

export default ProyectoComponent;
