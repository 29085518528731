import React,{useEffect} from "react";
import { FasesContainer } from "./FasesStyle";
import UbiIcon from "../../assets/images/ubiicon.svg";
const Pointcomponent = ({ apiImage, dataPoint, footerSelected,HandlerIsBackground }) => {

  useEffect(() => {
    HandlerIsBackground(true)
  }, [])

  const formattedCriterio = dataPoint?.criterios_de_decision?.replace(
    /\n/g,
    "</p><p>"
  );


  return (
    <FasesContainer>
      <div
        className="ContainerHeaderFases"
        style={{
          backgroundImage: `url(${apiImage}${dataPoint?.imagen})`,
        }}
      >
        <div className="ContainerFloatHeaderFase"
        style={{bottom: 10}}
        >
          <div
            className="ContainerTitleFase"
            style={{ background: dataPoint?.colores[0].color,
                      width: dataPoint?.numeropaso == "7" 
                      ||
                      dataPoint?.numeropaso == "9"
                      ||
                      dataPoint?.numeropaso == "10"
                      ||
                      dataPoint?.numeropaso == "15"
                      ||
                      dataPoint?.numeropaso == "17"
                      ? 230 
                      : 90
                    }}
          >
            <p style={dataPoint?.tipo === "checkpoint" ? {color: "#fff"} : {color: "#000"}}>
              {dataPoint?.numeropaso == "7"
                ? `IDEACIÓN Y PLAN DE NEGOCIOS`
                :
                dataPoint?.numeropaso == "9"
                ? `IDEACIÓN Y PLAN DE NEGOCIOS`
                :
                dataPoint?.numeropaso == "10"
                ?  `IDEACIÓN Y PLAN DE NEGOCIOS`
                :
                dataPoint?.numeropaso == "15"
                ? `Desarrollo`
                :
                dataPoint?.numeropaso == "17"
                ? `Desarrollo`
                : ""}
            </p>
          </div>
          <div className="ContainerTittleNameFases">
            <div className="NameFaseContainer">
              <div className="ContainerImgNameFase">
                <img
                  width={30}
                  src={UbiIcon}
                  alt=""
                  srcset=""
                />
              </div>
              <div className="ContainerNameFases">
                <p>{dataPoint?.titulo}</p>
              </div>
            </div>
            <div
              style={{ background: dataPoint?.colores[0].color }}
              className="lineNameFase"
            ></div>
          </div>
        </div>
      </div>
      <div>
        <div className="ContainerFlexFasesTop">
          <div className="ContainerFlexFasesLeft">
            <div className="ContainerDescriptionName">
              <h2>Acciones</h2>
             
            </div>
            <div className="LineBottonFases"></div>


            <div className="ContainerActionsFlexSteep">
                {dataPoint?.acciones?.map((r, index) => {
                  return (
                    <div>
                      <div className="ContainerTittleSteepSteep">
                        <div className="ContainerStepStep">
                          <p>
                            0.{index + 1}
                          </p>
                        </div>
                        <div className="ContainerTittleSteepSteepr">
                          <p>{r.titulo}</p>
                        </div>
                      </div>
                      <div className="ContainerSteepSteepDesc">
                        <p>{r.descripcion}</p>
                      </div>
                    </div>
                  );
                })}
              </div>


          </div>
          <div className="ContainerFlexFasesRight">
            <div className="ContainerFloatObjetivo">
              <div
               style={{marginBottom: 10}}
              className="ContianerObjetivoName">
                <p>Objetivo</p>
                {/* <div className="lineObjetivoFases"></div> */}
              </div>
              <div className="ContainerTextObjetivoFases">
                <p>{dataPoint?.objetivo}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </FasesContainer>
  );
};

export default Pointcomponent;
