import styled from "styled-components";
import { motion } from "framer-motion";
// globalstyles
import GlobalStyles from "../../theme/GlobalStyles";

export const FasesContainer = styled.div`
   
  .ContainerHeaderFases {
    height: 200px;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    z-index: -1;
  }
  .ButtonFase {
    position: relative;
    min-width: 40px;
    min-height: 17px;
    border-radius: 16px;
    background-clip: inherit;
    background-image: linear-gradient(
      rgb(63, 52, 139),
      rgb(74, 51, 140),
      rgb(84, 50, 141),
      rgb(94, 48, 141),
      rgb(103, 46, 141)
    );
    padding: 1px 2px;
    cursor: pointer;
    width: 80%;
    margin: auto;
    margin-top: 20px;
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .ButtonFase:hover{
      background: rgb(153, 38, 144);
    }
  .ButtonFase a {
    color: #fff;
    font-size: 14px;
    font-family: "Montserrat-Bold", sans-serif;
    font-weight: bold;
    text-decoration: none;
  }
  
  .ContainerStepStep p {
    font-size: 12px;
    font-weight: bold;
    margin: 0px;
    padding: 0px;
    margin-top: -3px;
    margin-left: -3px;
    color: #3f348b;
  }
  .ContainerStepStep {
    padding: 10px;
    width: 10px;
    height: 10px;
    background-color: rgba(63, 52, 139, 0.3);
    border-radius: 50%;
  }
  .ContainerFloatHeaderFase {
    position: absolute;
    bottom: 20px;
    left: 6%;
  }
  .ContainerTitleFase {
    border-radius: 5px;
    padding: 5px;
    width: 90px;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    margin-bottom: 20px;
  }
  .ContainerTitleFase p {
    color: black;
    font-size: 14px;
    font-family: "Montserrat-Bold", sans-serif;
    font-weight: bold;
  }
  .NameFaseContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .ContainerImgNameFase {
    margin-right: 10px;
  }
  .ContainerNameFases p {
    font-size: 2rem;
    color: #fff;
    font-family: "Montserrat-Bold", sans-serif;
    font-weight: bold;
  }
  .lineNameFase {
    margin-top: 10px;
    width: 60%;
    height: 5px;
  }
  .ContainerFlexFasesTop {
    display: flex;
    flex-direction: row;
  }
  .ContainerPDefinicion {
    width: 95%;
    margin: auto;
  }
  .ContainerFlexFasesLeft {
    flex: 3;
    text-align: initial;
  }
  .ContainerFlexFasesRight {
    flex: 2;
  }
  .ContainerDescriptionName {
    margin-top: 3%;
    width: 88%;
  }
  .ContainerDescriptionName h2 {
    font-family: "Montserrat-SemiBold", sans-serif;
    color: rgb(63, 52, 139);
    margin: 0px;
    padding: 0px;
    padding-bottom: 10px;
    font-size: 18px;
  }
  .ContainerDescriptionName p {
    font-family: "Montserrat-SemiBold", sans-serif;
    color: rgb(64, 63, 63);
    margin: 10px 0px;
    line-height: 22px;
    padding: 0px;
    font-size: 15px;
  }
  .ContainerFloatObjetivo {
    background: #fff;
    padding-top: 10px;
    border-radius: 10px;
    padding: 15px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px;
  }
  .ContianerObjetivoName {
    color: rgb(224, 27, 133);
    font-size: 14px;
    text-align: initial;
    font-family: "Montserrat-SemiBold", sans-serif;
    font-weight: bold;
  }
  .lineObjetivoFases {
    background: transparent
      linear-gradient(
        105deg,
        #ffffff 0%,
        #b9db10 4%,
        #219348 10%,
        #00a99d 17%,
        #3565ac 60%,
        #3f348b 70%,
        #9b2690 85%,
        #be0c13 100%
      )
      0% 0% no-repeat padding-box;
    width: 80%;
    height: 3px;
    margin-top: 5px;
    margin-bottom: 8px;
  }
  .ContainerTextObjetivoFases p {
    font-family: "Montserrat-Medium", sans-serif !important;
    font-size: 12px;
    text-align: initial;
    color: rgb(47, 34, 83);
  }
  .LineBottonFases {
    width: 100%;
    background: #efefef;
    height: 2px;
  }
  .ContainerCheksFlex {
    display: flex;
    justify-content: space-between;
  }
  .ContainerChekTitle {
    display: flex;
    align-items: center;
    margin-top: 8%;
  }
  .ContainerChekTitle h3 {
    font-family: "Montserrat-SemiBold", sans-serif;
    color: rgb(63, 52, 139);
    font-weight: bold;
  }
  .TextEntradasFases {
    font-family: "Montserrat-SemiBold", sans-serif;
    color: rgb(64, 63, 63);
    position: relative;
  }
  .TextEntradasFases::before {
    content: "";
    position: absolute;
    top: 10px;
    left: -8px;
    background: #3f348b;
    height: 4px;
    width: 4px;
    border-radius: 50%;
  }
  .ContainerChekTitle img {
    margin-right: 10px;
  }

  .ContainerTextEntradasFases {
    margin-top: 10px;
    max-width: 462px;
  }
  .ContainerTittleSteepSteep {
    display: flex;
    align-items: center;
  }
  .ContainerStepStep p {
    font-size: 12px;
    font-weight: bold;
    margin: 0px;
    padding: 0px;
    margin-top: -3px;
    margin-left: -3px;
    color: #3f348b;
  }
  .ContainerTittleSteepSteepr {
    text-align: initial;
  }
  .ContainerTittleSteepSteepr p {
    color: #3f348b;
    font-family: "Montserrat-Bold", sans-serif;
    font-weight: bold;
  }
  .ContainerSteepSteepDesc {
    text-align: initial;
    margin-top: 2%;
    font-family: "Montserrat-SemiBold", sans-serif;
    color: rgb(64, 63, 63);
  }
  // BUTTONS

  // Mobil Frist
  @media screen and (min-width: 200px) {
    .ContainerHeaderFases {
      margin-top: -95px;
      background-position-y: 0px;
    }
    .ContainerNameFases p {
      font-size: 1.2rem;
    }
    .ContainerFlexFasesTop {
      flex-direction: column-reverse;
      margin-left: 10%;
    }
    .ContainerFloatObjetivo {
      width: 70%;
      margin-top: -4%;
      margin-left: 8%;
    }
    .ContainerCheksFlex {
      flex-direction: column;
    }
    .ContainerActionsFlexSteep {
      display: grid;
      grid-template-columns: repeat(1fr);
      grid-gap: 16px;
      padding: 16px;
      width: 90%;
      margin: auto;
    }
    .lineNameFase {
      width: 100%;
    }
    .ContainerFloatHeaderFase {
      left: 10%;
    }
    .ContainerDescriptionName h2 {
      font-weight: 600;
      font-size: 16px;
    }
    .ContainerDescriptionName p {
      font-size: 12px;
    }
    .ContainerChekTitle h3 {
      font-size: 15px;
    }
    .TextEntradasFases {
      font-size: 13px;
      margin-left: 10%;
    }
    .ContainerTittleSteepSteepr {
      margin-left: 0px;
    }
    .ContainerTittleSteepSteepr p {
      color: #3f348b;
      font-size: 13px;
    }
    //height: ${props => props.tipo === "gate" ? '155vh' :  "105vh"};
  }
  @media screen and (max-width: 837px) {
    .ContainerFloatObjetivo {
  
    margin-top: -2%;
 
}
  }
  @media screen and (min-width: 720px) {
  }
  @media screen and (min-width: 900px) {
   // height: 90vh;
    .ContainerTittleSteepSteepr {
      margin-left: 10px;
      text-align: initial;
    }
    .ContainerTittleSteepSteepr p {
      color: #3f348b;
      font-size: 15px;
      font-family: "Montserrat-Bold", sans-serif;
      font-weight: bold;
    }
    .ContainerChekTitle h3 {
      font-size: 18px;
    }
    .TextEntradasFases {
      font-size: 15px;
      margin-left: 10%;
    }
    .ContainerDescriptionName p {
      font-size: 16px;
    }
    .ContainerDescriptionName h2 {
      font-size: 18px;
    }
    .ContainerFloatHeaderFase {
      left: 6%;
    }
    .lineNameFase {
      width: 60%;
    }
    .ContainerFlexFasesTop {
      flex-direction: row;
      margin-left: 4%;
      width: 100%;
    }
    .ContainerHeaderFases {
      margin-top: -84px;
      background-position-y: -254px;
    }
    .ContainerNameFases p {
      font-size: 2rem;
    }
    .ContainerFloatObjetivo {
      width: 50%;
      margin-top: -6%;
      margin-left: 10%;
    }
    .ContainerCheksFlex {
      flex-direction: row;
    }
    .ContainerChekLeft {
      width: 50%;
      padding-right: 20px;
    }
    .ContainerChekRight {
      width: 50%;
    }
    .ContainerActionsFlexSteep {
      grid-template-columns: repeat(3, 1fr);
      margin: 0px;
    }
    .ContainerActionsFlexSteep {
      grid-gap: 30px;
      padding: 16px;
      width: 100%;
    }
    .ContainerTextEntradasFases {
    max-width: 462px;
  }
  }

  @media screen and (min-width: 1200px) {
  }

  // LANSCAPE Mobil
  @media screen and (min-width: 838px) and (orientation: landscape) {
    // DESK
  }
  @media screen and (min-width: 930px) and (orientation: landscape) {
  }
`;

export const SteeperContainer = styled.div`

  .ContainerTopFlexStep {
    display: flex;
    justify-content: space-between;
    margin-top: 2%;
    padding-right: 10px;
  }
  .ContianerTopFlexLeft {
    text-align: initial;
    padding-right: 20px;
  }

  .ContainerFaseNamesteep {
    display: flex;
    align-items: center;
    padding-left: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-right: 10px;
    width: 200px;
    border-radius: 10px;
  }
  .ContainerFaseNamesteep img {
    margin-right: 10px;
  }
  .ContainerFaseNamesteep p {
    color: rgb(14, 2, 51);
    font-weight: bold;
    font-family: "Montserrat-Bold", sans-serif;
  }
  .ContainerTitleSteep {
    margin-top: 2%;
  }
  .ContainerTitleSteep p {
    color: #0e0233;
    font-family: "Montserrat-Bold", sans-serif;
    font-weight: bold;
    font-size: 1.8rem;
  }
  .LinetittlePasos {
    margin-top: 10px;
    width: 20%;
    height: 3px;
    margin-bottom: 2%;
  }
  .ContainerObjetivoSteep h3 {
    color: #e01b85;
    font-size: 18px;
    font-family: "Montserrat-Bold", sans-serif;
    font-weight: bold;
    margin-bottom: 10px;
  }
  .ContainerObjetivoSteep p {
    color: #403f3f;
    font-size: 14px;
    font-family: "Montserrat-SemiBold", sans-serif;
    font-weight: 600;
    margin-bottom: 20px;
  }
  .tittleaccionesSteep h3 {
    color: #3f348b;
    font-size: 18px;
    font-family: "Montserrat-Bold", sans-serif;
    font-weight: bold;
    margin-bottom: 10px;
  }
  .lineAccionesSteep {
    width: 100%;
    background: #0000004d;
    height: 2px;
    margin-bottom: 10px;
  }

  .ContainerStepStep {
    padding: 10px;
    width: 10px;
    height: 10px;
    background-color: rgba(63, 52, 139, 0.3);
    border-radius: 50%;
  }
  .ContainerTittleSteepSteep {
    display: flex;
    align-items: center;
  }
  .ContainerStepStep p {
    font-size: 12px;
    font-weight: bold;
    margin: 0px;
    padding: 0px;
    margin-top: -3px;
    margin-left: -3px;
    color: #3f348b;
  }
  .ContainerTittleSteepSteepr {
    text-align: initial;
  }
  .ContainerTittleSteepSteepr p {
    color: #3f348b;

    font-family: "Montserrat-Bold", sans-serif;
    font-weight: bold;
  }
  .ContainerSteepSteepDesc {
    text-align: initial;
    margin-top: 2%;
    font-family: "Montserrat-SemiBold", sans-serif;
    color: rgb(64, 63, 63);
  }

  // Mobil Frist
  @media screen and (min-width: 200px) {
    width: 83%;
    margin-left: 15%;
    .ContainerTopFlexStep {
      flex-direction: column;
    }
    .ContainerImgFlexRight img {
      width: 300px;
      border-radius: 10px;
      bottom: 0px;
      margin-left: -10%;
      margin-top: 10px;
    }
    .ContainerActionsFlexSteep {
      display: grid;
      grid-template-columns: repeat(1fr);
      grid-gap: 16px;
      padding: 16px;
      width: 90%;
      margin: auto;
      padding-left: 0px;
    }
    .ContainerFaseNamesteep {
      width: 80%;
    }
    .LinetittlePasos {
      width: 90%;
    }
    .tittleaccionesSteep h3 {
      font-size: 16px;
    }
    .ContainerObjetivoSteep p {
      font-size: 13px;
    }
    .ContainerTittleSteepSteepr {
      margin-left: 0px;
    }
    .ContainerTittleSteepSteepr p {
      color: #3f348b;
      font-size: 13px;
      margin-left: 10px;
    }
    .ContainerStepStep {
      padding: 10px;
      width: 6px;
      height: 6px;
      background-color: rgba(63, 52, 139, 0.3);
      border-radius: 50%;
    }
    
      .ContainerStepStep p {
    font-size: 10px;
    margin-top: -2px;
    margin-left: -4px;

  
    }
    .ContainerSteepSteepDesc p{
      font-size: 13px;
  }
 
  }
  @media screen and (min-width: 720px) {
  }
  @media screen and (min-width: 900px) {
    .ContainerTittleSteepSteep{
      max-width: 412px;
    }
    .ContainerSteepSteepDesc p{
      font-size: 15px;
      max-width: 420px;
  }
  //height: 75vh;
    .ContainerStepStep p {
    font-size: 12px;
    font-weight: bold;
    margin: 0px;
    padding: 0px;
    margin-top: -3px;
    margin-left: -4.4px;
    color: #3f348b;
  }
    .tittleaccionesSteep h3 {
      font-size: 18px;
    }
    .ContainerObjetivoSteep p {
      font-size: 14px;
     
    }
    .ContainerFaseNamesteep {
      width: 200px;
    }
    
    .LinetittlePasos {
      width: 30%;
      margin-bottom: 15px;
    }
    width: 85%;
    margin-left: 7%;
    .ContainerTopFlexStep {
      flex-direction: row;
    }
    .ContainerActionsFlexSteep {
      grid-template-columns: repeat(3, 1fr);
      width: 100%;
    }
    .ContainerImgFlexRight img {
      width: 400px;
      border-radius: 10px;
      position: initial;
      margin-top: 0px;
    }
    .ContianerTopFlexLeft {
    text-align: initial;
    padding-right: 90px;
  }
  }
  @media screen and (min-width: 1200px) {
  }

  // LANSCAPE Mobil
  @media screen and (min-width: 838px) and (orientation: landscape) {
    // DESK
  }
  @media screen and (min-width: 930px) and (orientation: landscape) {
  }
`;
