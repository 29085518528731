import React,{useEffect} from 'react'
import Objetivocomponent from '../components/Objetivos/Objetivocomponent'
import {ContainerObjetivo} from '../components/Objetivos/ObjetivoStyles'
import { useLocation } from "react-router-dom";
const Objetivo = ({
    dataObjetivo,SelectedRouteMenu,windowSize,HanlderSelectRouterMenu,HandlerRoute,HanlderSelectedFooter,HandlerIsBackground,ActualRouter}) => {
    const apiImage = process.env.REACT_APP_BASE_URLIMAGE;
    const location = useLocation();
    useEffect(() => {
        HandlerRoute(location.pathname);
        HanlderSelectedFooter(null)
        HandlerIsBackground(false)
      }, [ActualRouter]);
  return (

    
    <ContainerObjetivo>
        <div style={{width: '100%'}}>
        <Objetivocomponent
        dataObjetivo={dataObjetivo}
        Tipo={SelectedRouteMenu}
        apiImage={apiImage}
        windowSize={windowSize}
        HanlderSelectRouterMenu={HanlderSelectRouterMenu}
        />
        </div>
    </ContainerObjetivo>
  )
}

export default Objetivo