import React,{useContext} from 'react'
import useLogin from '../components/Login/hooks/useLogin'
import SplashScreen from '../components/splashScreen/SplashScreen'
import {LayoutPage} from '../theme/Layout'
import { useNavigate } from "react-router-dom";
// Contextos

// IMAGENES
import PanelImage from '../assets/images/login/panel.svg'
import LinesImege from '../assets/images/login/lines.svg'
import LoginComponent from '../components/Login/LoginComponent'
import { AuthContext } from '../context/AuthContext'

const Login = () => {
  const{login} = useContext(AuthContext)
    const{ShowSplash} = useLogin()
    const navigate = useNavigate();
  return (
    ShowSplash === true ?
    <SplashScreen />
    :
    <LayoutPage>
        <div className='ContainerTopImage'>
            <img src={PanelImage} alt="" srcset="" />
        </div>
        <div className='ContainerBottonImage'>
            <img src={LinesImege} alt="" srcset="" />
        </div>
       <LoginComponent
       login={login}
       navigate={navigate}
       ></LoginComponent>
    </LayoutPage>
  )
}

export default Login