import styled from "styled-components";
import { motion } from "framer-motion";
// globalstyles
import GlobalStyles from "../../theme/GlobalStyles";

export const ContainerObjetivo = styled.div`
  width: 90%;
  margin: auto;
  display: flex;
  flex: 1;

  .ContainerTopBarObjetivo {
    display: flex;
    justify-content: space-between;
  }

  .ContainerBodyObjetivo {
    margin-top: 2%;
  }
  .CotainerFlexObjetivo {
    display: flex;
    width: 90%;
    justify-content: space-between;
  }
  .ContainerTitleObjetivo h2 {
    font-family: "Montserrat-Bold", sans-serif;
    font-weight: bold;
    color: rgb(14, 2, 51);
  }
  .lineObjetivoFases {
    background: transparent
      linear-gradient(
        105deg,
        #ffffff 0%,
        #b9db10 4%,
        #219348 10%,
        #00a99d 17%,
        #3565ac 60%,
        #3f348b 70%,
        #9b2690 85%,
        #be0c13 100%
      )
      0% 0% no-repeat padding-box;
    width: 30%;
    height: 3px;
    margin-top: 5px;
    margin-bottom: 8px;
  }
  .ContainerTitleObjetivo {
    text-align: initial;

    margin-top: 10px;
  }
  .ContainerCopyObjetivo p {
    font-size: 14px;
    font-weight: normal;
    color: #403F3F;
    font-family: "Montserrat-Medium", sans-serif;
    padding: 0px;
    line-height: 20px;
    margin-bottom: 10px;
    position: relative;
    line-height: 22px;
  }
  /* .ContainerCopyObjetivo p::before {
    position: absolute;
    content: "";
    top: 8px;
    left: -10px;
    width: 7px;
    height: 7px;
    background: #000;
    border-radius: 50%;
  } */
  .ContainerImgObjetivo {
    width: 400px;
    height: 300px;
    position: relative;
  }

  .ContainerEvolucionarObjetivo {
    margin-top: 4%;
  }
  .ContainerDropDownObjetivo {
    border-bottom: 1px solid #efefef;
    margin-bottom: 10px;
   
  }
  .ContainerTitleDropDownObjetivo {
    display: flex;
    justify-content: space-between;
  }
  .ContainerTitleDropDownObjetivo h3 {
    color: rgb(63, 52, 139);
    font-size: 14px;
    line-height: 29px;
    font-family: "Montserrat-Bold", sans-serif;
  }
  .ContainerTextDropDownObjetivo{
    margin-bottom: 15px;
  }
  .ContainerTextDropDownObjetivo p {
    color: rgb(14, 2, 51);
    font-family: "Montserrat-Medium", sans-serif;
    font-size: 14px;
    line-height: 18px;
    margin-left: 2%;
    position: relative
  }
  /* .ContainerTextDropDownObjetivo p::before {
    content: "";
    position: absolute;
    top: 6px;
    left: -8px;
    background: #3f348b;
    height: 4px;
    width: 4px;
    border-radius: 50%;
  } */
  .ContainerPBullet::before {
    content: "";
    position: absolute;
    top: 6px;
    left: -8px;
    background: #3f348b;
    height: 4px;
    width: 4px;
    border-radius: 50%;
  }
  .ContainerSubtitleObjetivo {
    font-size: 18px;
    font-family: "Montserrat-Medium", sans-serif;
    font-size: 16px;
    letter-spacing: 0px;
    color: rgb(14, 2, 51);
    margin-bottom: 18px;
    font-weight: bold;
  }
  .ContainerColaboracion h3 {
    color: rgb(63, 52, 139);
    font-size: 14px;
    line-height: 29px;
    font-family: "Montserrat-Bold", sans-serif;
  }
  .ContainerFlexinnovar {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .ContainerFlexinnovar img {
    margin-right: 10px;
  }
  .ContainerFlexinnovar p {
    color: rgb(63, 52, 139);
    font-size: 14px;
    line-height: 29px;
    font-family: "Montserrat-Bold", sans-serif;
    font-weight: bold;
  }
  .ContainerTitleproyect{
    text-align: initial;
  }

  // Mobil Frist
  @media screen and (min-width: 200px) {
    .CotainerFlexObjetivo {
      flex-direction: column;
    }
    .ContainerTitleObjetivo {
      width: 100%;
    }
    .ContainerTitleObjetivo h2 {
      font-size: 1.5rem;
    }
    .ContainerBodyObjetivo {
      margin-left: 10%;
    }
    .ContainerImgObjetivo img {
      width: 50%;
      border-radius: 10px;
    }
    .ContainerCopyObjetivo p {
    font-size: 12px;
    line-height: 25px;
  }
  .lineObjetivoFases {
    width: 60%;
  }
  .ContainerImgObjetivo{
    margin-left: -22%;
    margin-top: 22px;
  }
  .ContainerPBullet{
    font-size: 12px !important;
    font-family: "Montserrat-Medium", sans-serif;
    color: #403F3F !important;
    margin-top: 10px;
  }
  .ContainerTextDropDownObjetivo p {
    color: #403F3F;
    font-size: 12px;
    line-height: 25px;
  }
  .ContainerSubtitleObjetivo {
    font-size: 16px;
  }
  .ContainerTitleDropDownObjetivo h3 {
  
    font-size: 14px;
    
  }
  }
  @media screen and (min-width: 720px) {
    .ContainerImgObjetivo img {
      width: 70%;
    }
  }
  @media screen and (min-width: 900px) {
    .ContainerTitleDropDownObjetivo h3 {
  
  font-size: 17px;
  
}
    .ContainerTextDropDownObjetivo p {
    color: #403F3F;
    font-size: 16px;
    line-height: 25px;
  }
    .lineObjetivoFases {
    width: 30%;
  }
    .CotainerFlexObjetivo {
      flex-direction: row;
      padding-bottom: 10%;
    }
    .ContainerTitleObjetivo {
      width: 50%;
    }
    .ContainerTitleObjetivo h2 {
      font-size: 2.3rem;
    }
    .ContainerBodyObjetivo {
      margin-left: 2%;
    }
    .ContainerCopyObjetivo p {
    font-size: 16px;
   
  }
  .ContainerCopyObjetivo{
    margin-top: 5%;
  }
  .ContainerPBullet{
    font-size: 16px !important;
  }
  .ContainerSubtitleObjetivo {
    font-size: 22px;
  }
  
  }
  @media screen and (min-width: 1200px) {
    .ContainerImgObjetivo img {
      width: 100%;
    }
  }

  // LANSCAPE Mobil
  @media screen and (min-width: 838px) and (orientation: landscape) {
    // DESK
  }
  @media screen and (min-width: 930px) and (orientation: landscape) {
  }
`;
