import styled from "styled-components"
import { motion } from "framer-motion";


export const ContainerLogin = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  align-items: center;
  align-content: center;
  position: relative;

  .ContainerCardAuth{
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
  }
  .containerbottom{
    position: absolute;
    width: 100%;
    height: 40px;
    bottom: 10px;
    right: 0px;
  }
  .continerLogoLogin{
    position: absolute;
  
  }

  // Mobil Frist
  @media screen and (min-width: 200px){
    .ContainerCardAuth img{
    width: 80%;
  }
  .ContainerCardAuth{
    margin-top: 0%;
  }
  .continerLogoLogin{
    bottom: 80px;
  }
  }
  @media screen and (min-width: 720px){
    
  }
  @media screen and (min-width: 900px){
    .ContainerCardAuth img{
    width: 100%;
  }
  .ContainerCardAuth{
    margin-top: 0%;
  }
  .continerLogoLogin{
    bottom: 50px;
  }
  }
  @media screen and (min-width: 1200px){

  }

  // LANSCAPE
  @media screen and (min-width: 838px) and (orientation: landscape) {
  .ContainerCardAuth img {
    width: 30%;
  }
  .ContainerCardAuth{
    margin-top: -10%;
  }
}
@media screen and (min-width: 930px) and (orientation: landscape) {
  .ContainerCardAuth img {
    width: 80%;
  }
  .ContainerCardAuth{
    margin-top: 0%;
  }
  
}
`
export const LineEffect = styled(motion.div)`
  width: 0;
  height: 8px;
  bottom: 0px;
  background-image: linear-gradient(90deg, #FFFFFF 0%, #B9DB10 4%, #219348 10%, #00A99D 17%, #3565AC 60%, #3F348B 70%, #9B2690 85%, #BE0C13 100%);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media screen and (min-width: 200px){
    

  }
  @media screen and (min-width: 720px){
    
  }
  @media screen and (min-width: 900px){
   
  }
  @media screen and (min-width: 1200px){

  }
`