import React, { useState } from "react";
import { ArrosContainer } from "./ArrosStyles";
import SiguienteImg from "../../assets/images/Siguiente.svg";
import AnteriorImg from "../../assets/images/Anerior.svg";
import { useNavigate } from "react-router-dom";
import SiguientesIcon from "../../assets/images/SiguientesIcon";
const Arrows = ({
  SelectedStep,
  Rutas,
  ActualRouter,
  HanlderSelectedStep,
  HanlderSelectRouteSteep,
  HanlderSelectedFooter,
  HandlerIsBackground,
  RutaSeleccionada
}) => {
  const navigate = useNavigate();
  const [pasoActual, setpasoActual] = useState(null);
  const SelectArrowBack = () => {
    switch (ActualRouter?.substring(1)) {
      case "definicion-estrategica":
        if (SelectedStep === null || SelectedStep === undefined) {
          navigate("/", { replace: true });
        } else {
          setpasoActual(Rutas[0]?.pasos[0]?.id);

          const indiceActual = Rutas[0]?.pasos.findIndex(
            (item) => item.id === SelectedStep.id
          );
          const siguienteRegistro =
            indiceActual !== -1 && indiceActual < Rutas[0]?.pasos.length - 1
              ? Rutas[0].pasos[indiceActual - 1]
              : null;
          if (siguienteRegistro !== null) {
            if (siguienteRegistro === undefined) {
              HanlderSelectedStep(null)
              navigate("/definicion-estrategica", { replace: true });
              HandlerIsBackground(true)
            } else {
              HanlderSelectRouteSteep(siguienteRegistro?.url);
              HanlderSelectedStep(siguienteRegistro);
            }
          } else {
            HanlderSelectedStep(null);
            HandlerIsBackground(true);
            HanlderSelectedFooter("IDEACIÓN Y PLAN DE NEGOCIOS");
            navigate("/ideacion", { replace: true });
          }
        }
        break;
      case "ideacion":
        if(RutaSeleccionada === "IMPORTACIONES"){
          if (SelectedStep === null || SelectedStep === undefined) {
          
            navigate("/definicion-estrategica", { replace: true });
          } else {
            
            setpasoActual(Rutas[0]?.pasos[0]?.id);
            const indiceActual = Rutas[0]?.pasos.findIndex(
              (item) => item.id === SelectedStep.id
            );
            const siguienteRegistro =
              indiceActual !== -1 && indiceActual < Rutas[0]?.pasos.length - 1
                ? Rutas[0].pasos[indiceActual - 1]
                : null;
            if (siguienteRegistro !== null) {
              if (siguienteRegistro === undefined) {
                HanlderSelectedStep(null)
                navigate("/ideacion", { replace: true });
                HandlerIsBackground(true)
              } else {
                HanlderSelectRouteSteep(siguienteRegistro?.url);
                HanlderSelectedStep(siguienteRegistro);
              }
            } else {
            
              HanlderSelectedStep(null);
              HandlerIsBackground(true);
              HanlderSelectedFooter("DESARROLLO");
              navigate("/desarrollo", { replace: true });
            }
          }
        } else{
          if (SelectedStep === null || SelectedStep === undefined) {
          
            navigate("/definicion-estrategica", { replace: true });
          } else {
            
            setpasoActual(Rutas[1]?.pasos[0]?.id);
            const indiceActual = Rutas[1]?.pasos.findIndex(
              (item) => item.id === SelectedStep.id
            );
            const siguienteRegistro =
              indiceActual !== -1 && indiceActual < Rutas[1]?.pasos.length - 1
                ? Rutas[1].pasos[indiceActual - 1]
                : null;
            if (siguienteRegistro !== null) {
              if (siguienteRegistro === undefined) {
                HanlderSelectedStep(null)
                navigate("/ideacion", { replace: true });
                HandlerIsBackground(true)
              } else {
                HanlderSelectRouteSteep(siguienteRegistro?.url);
                HanlderSelectedStep(siguienteRegistro);
              }
            } else {
            
              HanlderSelectedStep(null);
              HandlerIsBackground(true);
              HanlderSelectedFooter("DESARROLLO");
              navigate("/desarrollo", { replace: true });
            }
          }
        }
      
        break;
      case "desarrollo":
        if (SelectedStep === null || SelectedStep === undefined) {
          navigate("/ideacion", { replace: true });
        } else {
          setpasoActual(Rutas[2]?.pasos[0]?.id);
          const indiceActual = Rutas[2]?.pasos.findIndex(
            (item) => item.id === SelectedStep.id
          );
          const siguienteRegistro =
            indiceActual !== -1 && indiceActual < Rutas[2]?.pasos.length - 1
              ? Rutas[2].pasos[indiceActual - 1]
              : null;
          if (siguienteRegistro !== null) {
            if (siguienteRegistro === undefined) {
              HanlderSelectedStep(null)
              navigate("/desarrollo", { replace: true });
              HandlerIsBackground(true)
            } else {
              HanlderSelectRouteSteep(siguienteRegistro?.url);
              HanlderSelectedStep(siguienteRegistro);
            }
          } else {
            HanlderSelectedStep(null);
            HandlerIsBackground(true);
            HanlderSelectedFooter("FABRICACIÓN");
            navigate("/fabricacion", { replace: true });
          }
        }
        break;
      case "fabricacion":

      if(Rutas[3]?.pasos[0]?.url === undefined){
        if (SelectedStep === null || SelectedStep === undefined) {
          navigate("/desarrollo", { replace: true });
        } else {
          setpasoActual(Rutas[0]?.pasos[0]?.id);
          const indiceActual = Rutas[0]?.pasos.findIndex(
            (item) => item.id === SelectedStep.id
          );
          const siguienteRegistro =
            indiceActual !== -1 && indiceActual < Rutas[0]?.pasos.length - 1
              ? Rutas[0].pasos[indiceActual - 1]
              : null;
          if (siguienteRegistro !== null) {
            if (siguienteRegistro === undefined) {
              HanlderSelectedStep(null)
              navigate("/fabricacion", { replace: true });
              HandlerIsBackground(true)
            } else {
              HanlderSelectRouteSteep(siguienteRegistro?.url);
              HanlderSelectedStep(siguienteRegistro);
            }
          } else {
            HanlderSelectedStep(null);
            HandlerIsBackground(true);
            HanlderSelectedFooter("SEGUIMIENTO");
            navigate("/seguimiento", { replace: true });
          }
        }
      } else{
        if (SelectedStep === null || SelectedStep === undefined) {
          navigate("/desarrollo", { replace: true });
        } else {
          setpasoActual(Rutas[3]?.pasos[0]?.id);
          const indiceActual = Rutas[3]?.pasos.findIndex(
            (item) => item.id === SelectedStep.id
          );
          const siguienteRegistro =
            indiceActual !== -1 && indiceActual < Rutas[3]?.pasos.length - 1
              ? Rutas[3].pasos[indiceActual - 1]
              : null;
          if (siguienteRegistro !== null) {
            if (siguienteRegistro === undefined) {
              HanlderSelectedStep(null)
              navigate("/fabricacion", { replace: true });
              HandlerIsBackground(true)
            } else {
              HanlderSelectRouteSteep(siguienteRegistro?.url);
              HanlderSelectedStep(siguienteRegistro);
            }
          } else {
            HanlderSelectedStep(null);
            HandlerIsBackground(true);
            HanlderSelectedFooter("SEGUIMIENTO");
            navigate("/seguimiento", { replace: true });
          }
        }
      }

        break;
      case "seguimiento":
        if (SelectedStep === null || SelectedStep === undefined) {
          navigate("/fabricacion", { replace: true });
        } else {
          setpasoActual(Rutas[4]?.pasos[0]?.id);
          const indiceActual = Rutas[4]?.pasos.findIndex(
            (item) => item.id === SelectedStep.id
          );
          const siguienteRegistro =
            indiceActual !== -1 && indiceActual < Rutas[4]?.pasos.length - 1
              ? Rutas[4].pasos[indiceActual - 1]
              : null;
          if (siguienteRegistro !== null) {
            if (siguienteRegistro === undefined) {
              HanlderSelectedStep(null)
              navigate("/seguimiento", { replace: true });
              HandlerIsBackground(true)
            } else {
              HanlderSelectRouteSteep(siguienteRegistro?.url);
              HanlderSelectedStep(siguienteRegistro);
            }
          } else {
          }
        }
        break;
      case "objetivo":
        break;
      case "tipos-de-proyectos":
        break;
      default:
        break;
    }
  };
  const SelectArrowNext = () => {
    
    switch (ActualRouter?.substring(1)) {
      case "definicion-estrategica":
        if (SelectedStep === null || SelectedStep === undefined) {
          HanlderSelectRouteSteep(Rutas[0]?.pasos[0]?.url);
          HanlderSelectedStep(Rutas[0]?.pasos[0]);
        } else {
          setpasoActual(Rutas[0]?.pasos[0]?.id);
          const indiceActual = Rutas[0]?.pasos.findIndex(
            (item) => item.id === SelectedStep.id
          );
          const siguienteRegistro =
            indiceActual !== -1 && indiceActual < Rutas[0]?.pasos.length - 1
              ? Rutas[0].pasos[indiceActual + 1]
              : null;
          if (siguienteRegistro !== null) {
            HanlderSelectRouteSteep(siguienteRegistro?.url);
            HanlderSelectedStep(siguienteRegistro);
          } else {
            HanlderSelectedStep(null);
            HandlerIsBackground(true);
            HanlderSelectedFooter("IDEACIÓN Y PLAN DE NEGOCIOS");
            navigate("/ideacion", { replace: true });
          }
        }
        break;
      case "ideacion":

      if(RutaSeleccionada === "IMPORTACIONES"){
        if (SelectedStep === null || SelectedStep === undefined) {
          HanlderSelectRouteSteep(Rutas[0]?.pasos[0]?.url);
          HanlderSelectedStep(Rutas[0]?.pasos[0]);
        } else {
          setpasoActual(Rutas[1]?.pasos[0]?.id);
          const indiceActual = Rutas[0]?.pasos.findIndex(
            (item) => item.id === SelectedStep.id
          );
          const siguienteRegistro =
            indiceActual !== -1 && indiceActual < Rutas[0]?.pasos.length - 1
              ? Rutas[0].pasos[indiceActual + 1]
              : null;
          if (siguienteRegistro !== null) {
            HanlderSelectRouteSteep(siguienteRegistro?.url);
            HanlderSelectedStep(siguienteRegistro);
          } else {
            HanlderSelectedStep(null);
            HandlerIsBackground(true);
            HanlderSelectedFooter("DESARROLLO");
            navigate("/desarrollo", { replace: true });
          }
        }
      }else {
        if (SelectedStep === null || SelectedStep === undefined) {
          HanlderSelectRouteSteep(Rutas[1]?.pasos[0]?.url);
          HanlderSelectedStep(Rutas[1]?.pasos[0]);
        } else {
          setpasoActual(Rutas[1]?.pasos[0]?.id);
          const indiceActual = Rutas[1]?.pasos.findIndex(
            (item) => item.id === SelectedStep.id
          );
          const siguienteRegistro =
            indiceActual !== -1 && indiceActual < Rutas[1]?.pasos.length - 1
              ? Rutas[1].pasos[indiceActual + 1]
              : null;
          if (siguienteRegistro !== null) {
            HanlderSelectRouteSteep(siguienteRegistro?.url);
            HanlderSelectedStep(siguienteRegistro);
          } else {
            HanlderSelectedStep(null);
            HandlerIsBackground(true);
            HanlderSelectedFooter("DESARROLLO");
            navigate("/desarrollo", { replace: true });
          }
        }
      }



        break;
      case "desarrollo":
        if(RutaSeleccionada === "IMPORTACIONES"){
          if (SelectedStep === null || SelectedStep === undefined) {
            console.log("first")
            HanlderSelectRouteSteep(Rutas[1]?.pasos[0]?.url);
            HanlderSelectedStep(Rutas[1]?.pasos[0]);
          } else {
            console.log("DDS")
            setpasoActual(Rutas[1]?.pasos[0]?.id);
            const indiceActual = Rutas[1]?.pasos.findIndex(
              (item) => item.id === SelectedStep.id
            );
            const siguienteRegistro =
              indiceActual !== -1 && indiceActual < Rutas[1]?.pasos.length - 1
                ? Rutas[1].pasos[indiceActual + 1]
                : null;
            if (siguienteRegistro !== null) {
              HanlderSelectRouteSteep(siguienteRegistro?.url);
              HanlderSelectedStep(siguienteRegistro);
            } else {
              HanlderSelectedStep(null);
              HandlerIsBackground(true);
              HanlderSelectedFooter("FABRICACIÓN");
              navigate("/fabricacion", { replace: true });
            }
          }
        } else{
          if (SelectedStep === null || SelectedStep === undefined) {
            console.log("first")
            HanlderSelectRouteSteep(Rutas[2]?.pasos[0]?.url);
            HanlderSelectedStep(Rutas[2]?.pasos[0]);
          } else {
            console.log("DDS")
            setpasoActual(Rutas[2]?.pasos[0]?.id);
            const indiceActual = Rutas[2]?.pasos.findIndex(
              (item) => item.id === SelectedStep.id
            );
            const siguienteRegistro =
              indiceActual !== -1 && indiceActual < Rutas[2]?.pasos.length - 1
                ? Rutas[2].pasos[indiceActual + 1]
                : null;
            if (siguienteRegistro !== null) {
              HanlderSelectRouteSteep(siguienteRegistro?.url);
              HanlderSelectedStep(siguienteRegistro);
            } else {
              HanlderSelectedStep(null);
              HandlerIsBackground(true);
              HanlderSelectedFooter("FABRICACIÓN");
              navigate("/fabricacion", { replace: true });
            }
          }
        }
       
        break;
      case "fabricacion":
        if(RutaSeleccionada === "IMPORTACIONES"){
          if(Rutas[3]?.pasos[0]?.url === undefined){
            if (SelectedStep === null || SelectedStep === undefined) {
              HanlderSelectRouteSteep(Rutas[0]?.pasos[0]?.url);
              HanlderSelectedStep(Rutas[0]?.pasos[0]);
            } else {
              setpasoActual(Rutas[0]?.pasos[0]?.id);
              const indiceActual = Rutas[0]?.pasos.findIndex(
                (item) => item.id === SelectedStep.id
              );
              const siguienteRegistro =
                indiceActual !== -1 && indiceActual < Rutas[0]?.pasos.length - 1
                  ? Rutas[0].pasos[indiceActual + 1]
                  : null;
              if (siguienteRegistro !== null) {
                HanlderSelectRouteSteep(siguienteRegistro?.url);
                HanlderSelectedStep(siguienteRegistro);
              } else {
                HanlderSelectedStep(null);
                HandlerIsBackground(true);
                HanlderSelectedFooter("SEGUIMIENTO");
                navigate("/seguimiento", { replace: true });
              }
            }
          } else{
            if (SelectedStep === null || SelectedStep === undefined) {
              HanlderSelectRouteSteep(Rutas[2]?.pasos[0]?.url);
              HanlderSelectedStep(Rutas[2]?.pasos[0]);
            } else {
              setpasoActual(Rutas[2]?.pasos[0]?.id);
              const indiceActual = Rutas[2]?.pasos.findIndex(
                (item) => item.id === SelectedStep.id
              );
              const siguienteRegistro =
                indiceActual !== -1 && indiceActual < Rutas[2]?.pasos.length - 1
                  ? Rutas[2].pasos[indiceActual + 1]
                  : null;
              if (siguienteRegistro !== null) {
                HanlderSelectRouteSteep(siguienteRegistro?.url);
                HanlderSelectedStep(siguienteRegistro);
              } else {
                HanlderSelectedStep(null);
                HandlerIsBackground(true);
                HanlderSelectedFooter("SEGUIMIENTO");
                navigate("/seguimiento", { replace: true });
              }
            }
          }
        } else{
          if(Rutas[3]?.pasos[0]?.url === undefined){
            if (SelectedStep === null || SelectedStep === undefined) {
              HanlderSelectRouteSteep(Rutas[0]?.pasos[0]?.url);
              HanlderSelectedStep(Rutas[0]?.pasos[0]);
            } else {
              setpasoActual(Rutas[0]?.pasos[0]?.id);
              const indiceActual = Rutas[0]?.pasos.findIndex(
                (item) => item.id === SelectedStep.id
              );
              const siguienteRegistro =
                indiceActual !== -1 && indiceActual < Rutas[0]?.pasos.length - 1
                  ? Rutas[0].pasos[indiceActual + 1]
                  : null;
              if (siguienteRegistro !== null) {
                HanlderSelectRouteSteep(siguienteRegistro?.url);
                HanlderSelectedStep(siguienteRegistro);
              } else {
                HanlderSelectedStep(null);
                HandlerIsBackground(true);
                HanlderSelectedFooter("SEGUIMIENTO");
                navigate("/seguimiento", { replace: true });
              }
            }
          } else{
            if (SelectedStep === null || SelectedStep === undefined) {
              HanlderSelectRouteSteep(Rutas[3]?.pasos[0]?.url);
              HanlderSelectedStep(Rutas[3]?.pasos[0]);
            } else {
              setpasoActual(Rutas[3]?.pasos[0]?.id);
              const indiceActual = Rutas[3]?.pasos.findIndex(
                (item) => item.id === SelectedStep.id
              );
              const siguienteRegistro =
                indiceActual !== -1 && indiceActual < Rutas[3]?.pasos.length - 1
                  ? Rutas[3].pasos[indiceActual + 1]
                  : null;
              if (siguienteRegistro !== null) {
                HanlderSelectRouteSteep(siguienteRegistro?.url);
                HanlderSelectedStep(siguienteRegistro);
              } else {
                HanlderSelectedStep(null);
                HandlerIsBackground(true);
                HanlderSelectedFooter("SEGUIMIENTO");
                navigate("/seguimiento", { replace: true });
              }
            }
          }
        }
           
        break;
      case "seguimiento":
        if(RutaSeleccionada === "IMPORTACIONES"){
          if (SelectedStep === null || SelectedStep === undefined) {
            HanlderSelectRouteSteep(Rutas[3]?.pasos[0]?.url);
            HanlderSelectedStep(Rutas[3]?.pasos[0]);
          } else {
            setpasoActual(Rutas[3]?.pasos[0]?.id);
            const indiceActual = Rutas[3]?.pasos.findIndex(
              (item) => item.id === SelectedStep.id
            );
            const siguienteRegistro =
              indiceActual !== -1 && indiceActual < Rutas[3]?.pasos.length - 1
                ? Rutas[3].pasos[indiceActual + 1]
                : null;
            if (siguienteRegistro !== null) {
              HanlderSelectRouteSteep(siguienteRegistro?.url);
              HanlderSelectedStep(siguienteRegistro);
            } else {
            }
          }
        } else {
          if (SelectedStep === null || SelectedStep === undefined) {
            HanlderSelectRouteSteep(Rutas[4]?.pasos[0]?.url);
            HanlderSelectedStep(Rutas[4]?.pasos[0]);
          } else {
            setpasoActual(Rutas[4]?.pasos[0]?.id);
            const indiceActual = Rutas[4]?.pasos.findIndex(
              (item) => item.id === SelectedStep.id
            );
            const siguienteRegistro =
              indiceActual !== -1 && indiceActual < Rutas[4]?.pasos.length - 1
                ? Rutas[4].pasos[indiceActual + 1]
                : null;
            if (siguienteRegistro !== null) {
              HanlderSelectRouteSteep(siguienteRegistro?.url);
              HanlderSelectedStep(siguienteRegistro);
            } else {
            }
          }
        }
      
        break;
      case "objetivo":
        break;
      case "tipos-de-proyectos":
        break;
      default:
        break;
    }
  };

  return (
    <ArrosContainer
      style={
        ActualRouter === "/definicion-estrategica" ||
        ActualRouter === "/ideacion" ||
        ActualRouter === "/desarrollo" ||
        ActualRouter === "/fabricacion" ||
        ActualRouter === "/seguimiento"
          ? { display: "flex" }
          : { display: "none" }
      }
    >
      <div
        // style={SelectedStep !== null ? {display: 'flex'} : {display: 'none'} }
        onClick={() => SelectArrowBack()}
        className="ContainerArrowFlex"
      >
        <img src={AnteriorImg} alt="" srcset="" />
        <p>Volver</p>
      </div>
      <div>
        <p>{RutaSeleccionada}</p>
      </div>

      <div onClick={() => SelectArrowNext()} className="ContainerArrowFlex">
        <p>Avanzar</p>
        <SiguientesIcon></SiguientesIcon>
      </div>
    </ArrosContainer>
  );
};

export default Arrows;
