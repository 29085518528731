import React, { useState, useEffect } from "react";
import ReactModal from "react-modal";
import "./prueba.css";
import { ModalLayout, ModalManualLayout } from "./ModalStyles";
import CloseImg from "../../../assets/images/menu/CloseSvg.svg";
import HomeComponent from "../../../components/home/HomeComponent";
import TopBar from "../../../components/home/TopBar";
// CMS

const ModalImage = ({ modal, closemodal, dataRoute, Tipo, windowSize,children }) => {

  const [modalStyles, setModalStyles] = useState({
    content: {
      width: "100%",
      left: "0%",
      height: "100vh",
      top: "0%",
      transform: "translate(-0%, -0%) translateX(-100%)",
      transition: "transform 0.3s ease-in-out",
      borderTopRightRadius: "25px",
      overflow: "scroll",
      background: "none",
    },
    overlay: {
      // backgroundColor: "rgba(0, 0, 0, 0.5)",
      opacity: 0,
      transition: "opacity 0.3s ease-in-out",
    },
  });

  useEffect(() => {
    if (modal) {
      document.body.style.overflow = "hidden";
      setModalStyles((prevStyles) => ({
        ...prevStyles,
        content: {
          ...prevStyles.content,
          transform: "translate(-0%, -0%) translateX(0%)",
        },
        overlay: {
          ...prevStyles.overlay,
          opacity: 1,
          zIndex: 99,
        },
      }));
    } else {
      document.body.style.overflow = "auto";
      setModalStyles((prevStyles) => ({
        ...prevStyles,
        content: {
          ...prevStyles.content,
          transform: "translate(-0%, -0%) translateX(-100%)",
        },
      }));
    }
  }, [modal]);
  const [RutaSeleccionada, setRutaSeleccionada] = useState("TODOS");
  const [ColorSeleccionado, setColorSeleccionado] = useState("");

  const HanlderSelectRouter = (data, color) => {
    setRutaSeleccionada(data);
    setColorSeleccionado(color);
  };


  const iniciativas = [
    {
        "id": 1,
        "nombre": "ARTES",
        "color": "#FFC200"
    },
    {
        "id": 2,
        "nombre": "COMBO",
        "color": "#E2006C"
    },
    {
        "id": 3,
        "nombre": "EXHIBICIÓN",
        "color": "#8F00AD"
    },
    {
        "id": 4,
        "nombre": "RÉPLICA",
        "color": "#21CEA6"
    },
    {
        "id": 5,
        "nombre": "INICIATIVA INCREMENTAL",
        "color": "#7AD800"
    },
    {
        "id": 6,
        "nombre": "INICIATIVA DISRUPTIVA",
        "color": "#0080FF"
    },
    {
        "id": 7,
        "nombre": "IMPORTACIONES",
        "color": "#00BF1B"
    }
]
  const order = [
    "TODAS",
    "INICIATIVA DISRUPTIVA",
    "COMBO",
    "RÉPLICA",
    "EXHIBICIÓN",
    "ARTES",
    "IMPORTACIONES",
    "INICIATIVA INCREMENTAL",
  ];
  const orderMovil = [
    "TODAS",
    "ARTES",
    "COMBO",
    "EXHIBICIÓN",
    "RÉPLICA",
    "INICIATIVA DISRUPTIVA",
    "INICIATIVA INCREMENTAL",
    "IMPORTACIONES",
  ];

  const sortedIniciativas = order
    .map((nombre) =>
      iniciativas.find(
        (iniciativa) => iniciativa && iniciativa.nombre === nombre
      )
    )
    .filter(Boolean);

  const [iniciativasList, setIniciativasList] = useState(sortedIniciativas);

  const sortedIniciativasMovil = orderMovil
    .map((nombre) =>
      iniciativas.find(
        (iniciativa) => iniciativa && iniciativa.nombre === nombre
      )
    )
    .filter(Boolean);

  const [iniciativasListMovil, setIniciativasListMovil] = useState(
    sortedIniciativasMovil
  );


  return (
    <ReactModal
      isOpen={modal}
      contentLabel="onRequestClose Example"
      onRequestClose={closemodal}
      shouldCloseOnOverlayClick={false}
      style={modalStyles}
      ariaHideApp={false}
    >
      <div className={modal ? "blur-background" : ""}></div>
      <ModalLayout>
        <ModalManualLayout>
          <div
            className={
              windowSize.width <= 837
                ? "CloseBotonModalMobil"
                : "CloseBotonModalDesk"
            }
            onClick={() => closemodal()}
          >
            <img src={CloseImg} alt="" srcset="" />
          </div>
          {/* <div className="ContainerTitle">
            <h3>Macroproceso de gestión de iniciativas e innovación</h3>
            <div className="LineGradientModalTitle"></div>
          </div> */}
         
          <div className="BodyInfomodal">
              {children}
          </div>
        </ModalManualLayout>
      </ModalLayout>
    </ReactModal>
  );
};

export default ModalImage;
