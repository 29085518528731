import styled from "styled-components";
import { motion } from "framer-motion";
// globalstyles
import GlobalStyles from "../../theme/GlobalStyles";

export const ArrosContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 10px;

 
  left: 10%;
  .ContainerArrowFlex {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    z-index: 5;
  }
  .ContainerArrowFlex p {
    font-family: 'Montserrat-SemiBold', sans-serif;
   color: #3F348B;
   font-size: 14px;
    margin-left: 10px;
    margin-right: 10px;
  }
  .ContainerArrowFlex :hover{
    color: rgb(153, 38, 144);
    fill: rgb(153, 38, 144)
    }
  // Mobil Frist
  @media screen and (min-width: 200px) {
    width: 90%;
  margin: auto;

  }
  @media screen and (min-width: 720px) {
  }
  @media screen and (min-width: 900px) {
    width: 95%;
  margin: auto;

  }
  @media screen and (min-width: 1200px) {
  }

  // LANSCAPE Mobil
  @media screen and (min-width: 838px) and (orientation: landscape) {
    // DESK
  }
  @media screen and (min-width: 930px) and (orientation: landscape) {
  }
`;
