import React from "react";
import { FasesContainer } from "./FasesStyle";
import Checkicon from "../../assets/images/CheckIcon.svg";
const FaseComponent = ({ apiImage, dataFases, footerSelected }) => {
  const formattedSalidas = dataFases?.salidas.replace(/\n/g, "</p><p>");


  const SalidasText = formattedSalidas
    ?.split("</p>")
    .map((paragraph, index) => (
      <p
        className="TextEntradasFases"
        key={index}
        dangerouslySetInnerHTML={{ __html: paragraph }}
      />
    ));

  const formattedEntreadas = dataFases?.entradas.replace(/\n/g, "</p><p>");

  const EntradasText = formattedEntreadas
    ?.split("</p>")
    .map((paragraph, index) => (
      <p
        className="TextEntradasFases"
        key={index}
        dangerouslySetInnerHTML={{ __html: paragraph }}
      />
    ));

  return (
    <FasesContainer>
      <div
        className="ContainerHeaderFases"
        style={{
          backgroundImage: `url(${apiImage}${dataFases?.imagen})`,
        }}
      >
        <div className="ContainerFloatHeaderFase">
          <div
            className="ContainerTitleFase"
            style={{ background: dataFases?.color }}
          >
            <p>
              {footerSelected === "DEFINICIÓN ESTRATÉGICA"
                ? "Fase 1"
                : footerSelected === "IDEACIÓN Y PLAN DE NEGOCIOS"
                ? "Fase 2"
                : footerSelected === "DESARROLLO"
                ? "Fase 3"
                : footerSelected === "FABRICACIÓN"
                ? "Fase 4"
                : footerSelected === "SEGUIMIENTO"
                ? "Fase 5"
                : ""}
            </p>
          </div>
          <div className="ContainerTittleNameFases">
            <div className="NameFaseContainer">
              <div className="ContainerImgNameFase">
                <img
                  width={30}
                  src={`${apiImage}${dataFases?.icono}`}
                  alt=""
                  srcset=""
                />
              </div>
              <div className="ContainerNameFases">
                <p>{dataFases?.nombre}</p>
              </div>
            </div>
            <div
              style={{ background: dataFases?.color }}
              className="lineNameFase"
            ></div>
          </div>
        </div>
      </div>
      <div className="ContainerPDefinicion">
        <div className="ContainerFlexFasesTop">
          <div className="ContainerFlexFasesLeft">
            <div className="ContainerDescriptionName">
              <h2>Descripción</h2>
              <p>{dataFases?.descripcion}</p>
            </div>
            <div className="LineBottonFases"></div>
            <div className="ContainerCheksFlex">
              <div className="ContainerChekLeft">
                <div className="ContainerChekTitle">
                  <img src={Checkicon} alt="CheckIcon" srcset="" />
                  <h3>Entradas</h3>
                </div>
                <div className="ContainerTextEntradasFases">
                  <p>{EntradasText}</p>
                </div>
              </div>
              <div className="ContainerChekRight">
                <div className="ContainerChekTitle">
                  <img src={Checkicon} alt="CheckIcon" srcset="" />
                  <h3>Salidas</h3>
                </div>
                <div className="ContainerTextEntradasFases">
                  <p>{SalidasText}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="ContainerFlexFasesRight">
            <div className="ContainerFloatObjetivo">
              <div
                style={{ marginBottom: 10 }}
                className="ContianerObjetivoName"
              >
                <p>Objetivo</p>
                {/* <div className="lineObjetivoFases"></div> */}
              </div>
              <div className="ContainerTextObjetivoFases">
                <p>{dataFases?.objetivo}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </FasesContainer>
  );
};

export default FaseComponent;
