import React from 'react'

// Styles
import { ContainerLogin, LineEffect } from './LoginStyles';
// Imagenes
import LogoImg from '../../assets/images/logocosmos.png'
import LogoGris from '../../assets/images/HerdezGris.svg'
import LoginButtonComponent from '../../utils/components/LoginButtonComponent'
const LoginComponent = ({login,navigate}) => {
  return (
    <ContainerLogin>
         <div className='ContainerCardAuth'>
            <img src={LogoImg} alt="" srcset="" />
            <LoginButtonComponent
            login={login}
            navigate={navigate}
            ></LoginButtonComponent>
            <div>

            </div>
        </div>
        <div className='continerLogoLogin'>
            <img src={LogoGris} alt="" srcset="" />
        </div>
        <div className='containerbottom'>
        <LineEffect
        initial={{ width: 0 }}
        animate={{ width: '100%' }}
        transition={{ duration: 1, delay: 0.5 }}
      />
        </div>
    </ContainerLogin>
  )
}

export default LoginComponent