import React, { useState, useEffect } from "react";
import ReactModal from "react-modal";
import "./prueba.css";
import { ModalLayout, ModalManualLayout } from "./ModalStyles";
import useCMS from "../../../api/CMS/useCMS";
import CloseImg from "../../../assets/images/menu/CloseSvg.svg";
// CMS

const ModalManual = ({ modal, closemodal, dataRoute, Tipo, windowSize }) => {
  const { LoadDataManual, Datamanual } = useCMS();
  const apiUrl = process.env.REACT_APP_BASE_URL;
  const apiImage = process.env.REACT_APP_BASE_URLIMAGE;
  const [modalStyles, setModalStyles] = useState({
    content: {
      width: "100%",
      left: "0%",
      height: "100vh",
      top: "0%",
      transform: "translate(-0%, -0%) translateX(-100%)",
      transition: "transform 0.3s ease-in-out",
      borderTopRightRadius: "25px",
      overflow: "scroll",
      background: "none",
    },
    overlay: {
      // backgroundColor: "rgba(0, 0, 0, 0.5)",
      opacity: 0,
      transition: "opacity 0.3s ease-in-out",
    },
  });

  useEffect(() => {
    if (modal) {
      document.body.style.overflow = "hidden";
      setModalStyles((prevStyles) => ({
        ...prevStyles,
        content: {
          ...prevStyles.content,
          transform: "translate(-0%, -0%) translateX(0%)",
        },
        overlay: {
          ...prevStyles.overlay,
          opacity: 1,
          zIndex: 99,
        },
      }));
    } else {
      document.body.style.overflow = "auto";
      setModalStyles((prevStyles) => ({
        ...prevStyles,
        content: {
          ...prevStyles.content,
          transform: "translate(-0%, -0%) translateX(-100%)",
        },
      }));
    }
  }, [modal]);

  useEffect(() => {
    if (Tipo === "Manual" && modal) {
      LoadDataManual(`${apiUrl}manual-de-uso`);
    } else {
    }
  }, [modal]);

  const itemsTop = Datamanual?.slice(0, 3);
  const itemsBottom = Datamanual?.slice(3);

  return (
    <ReactModal
      isOpen={modal}
      contentLabel="onRequestClose Example"
      onRequestClose={closemodal}
      shouldCloseOnOverlayClick={false}
      style={modalStyles}
      ariaHideApp={false}
    >
      <div className={modal ? "blur-background" : ""}></div>
      <ModalLayout>
        <ModalManualLayout>
          <div className={windowSize.width <= 837 ? "CloseBotonModalMobil" :"CloseBotonModalDesk"} onClick={() => closemodal()}>
            <img src={CloseImg} alt="" srcset="" />
          </div>
          <div className="ContainerTitle">
            <h3>Manual de uso</h3>
            <div className="LineGradientModalTitle"></div>
          </div>

          <div className="BodyInfomodal">
            <diiv className="conatinerInfoModal">
              <div className="ContainerInfoModalLeft">
                {itemsTop?.map((res) => {
                  const fragmentos = res.descripcion?.split(". ");

                  return (
                    <div className="ContainerBodyItemModal">
                      <div className="ContainerTitleItemModal">
                        <img
                          width={windowSize.width <= 837 ? 20 : 35}
                          height={35}
                          src={`${apiImage}${res.icono}`}
                          alt=""
                          srcset=""
                        />
                        <p>{res.nombre}</p>
                      </div>
                      <div className="ContainerDeskriptionModal">
                        {fragmentos.map((TextInfo) => {
                          return <p>{TextInfo}</p>;
                        })}
                          {res.decisiones !== null ? (
                        res.decisiones.map((resd) => {
                          return (
                            <div className="ContainerBodyItemModal">
                              <div className="ContainerTitleItemModal">
                                <img
                                   width={windowSize.width <= 837 ? 14 : 20}
                                  src={`${apiImage}${resd.icono_decision}`}
                                  alt=""
                                  srcset=""
                                />
                                <p>{resd.nombre_decision}</p>
                              </div>
                              <div className="ContainerDeskriptionModal">
                                <p>{resd.descripcion_decision}</p>
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <></>
                      )}
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="ContainerInfoModalRight">
                {itemsBottom?.map((res) => {
                  const fragmentos = res.descripcion?.split(". ");
                  return (
                    <div className="ContainerBodyItemModal">
                      <div className="ContainerTitleItemModal">
                        <img
                           width={windowSize.width <= 837 ? 20 : 35}
                           height={windowSize.width <= 837 ? 20 : 35}
                          src={`${apiImage}${res.icono}`}
                          alt=""
                          srcset=""
                        />
                        <p>{res.nombre}</p>
                      </div>
                      <div className="ContainerDeskriptionModal">
                        {fragmentos.map((TextInfo) => {
                          return <p>{TextInfo}</p>;
                        })}
                      </div>
                      {res.decisiones !== null ? (
                        res.decisiones.map((resd) => {
                          return (
                            <div className="ContainerBodyItemModal">
                              <div className="ContainerTitleItemModal">
                                <img
                                   width={windowSize.width <= 837 ? 14 : 20}
                                  src={`${apiImage}${resd.icono_decision}`}
                                  alt=""
                                  srcset=""
                                />
                                <p>{resd.nombre_decision}</p>
                              </div>
                              <div className="ContainerDeskriptionModal">
                                <p>{resd.descripcion_decision}</p>
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <></>
                      )}
                    </div>
                  );
                })}
              </div>
            </diiv>
          </div>
        </ModalManualLayout>
      </ModalLayout>
    </ReactModal>
  );
};

export default ModalManual;
