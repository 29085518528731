import styled from "styled-components"
import { motion } from "framer-motion";

export const ContainerSplash = styled.div`
  display: flex;
  flex: 1;
  height: 100vh;
  justify-content: center;
  align-items: center;
  position: relative;

  .containerSpalsh{
    width: 87%;
  }
`