import styled from "styled-components"
import { motion } from "framer-motion";

export const LayoutPage = styled.div`
  display: flex;
  flex: 1;
  height: 100vh;
  flex-direction: column;
  position: relative;

  .ContainerTopImage{
    position: absolute;
    top: 20px;
    right: 20px
  }
  .ContainerBottonImage{
    position: absolute;
    bottom: 0px;
    left: 0px;
    z-index: -1
  }
  // Mobil Frist
  @media screen and (min-width: 200px){
    .ContainerTopImage img{
    width: 70%
  }
  .ContainerBottonImage img{
    width: 90%;
  }
  }
  @media screen and (min-width: 720px){
    
  }
  @media screen and (min-width: 900px){
   
  }
  @media screen and (min-width: 1200px){
    .ContainerTopImage img{
    width: 80%
  }
  }
`

export const Layout = styled.div`
    height: 100vh;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    

  // Mobil Frist
  @media screen and (min-width: 200px){
   
  }
  @media screen and (min-width: 720px){
    
  }
  @media screen and (min-width: 900px){
   
  }
  @media screen and (min-width: 1200px){

  }

  // LANSCAPE Mobil
  @media screen and (min-width: 838px) and (orientation: landscape) {
 
    // DESK
}
@media screen and (min-width: 930px) and (orientation: landscape) {
  
}

// MODALES
.custom-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  max-width: 600px; /* Puedes ajustar este valor según tus necesidades */
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
  padding: 20px;
}

.custom-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}
.prueba{
  font-size: 33px;
}
`