// GlobalStyles.js
import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`

  /* Fuentes */

  /* Montserrat-Bold */
  @font-face {
    font-family: 'Montserrat-Bold';
    src: url('../assets/fonts/Montserrat-Bold.ttf');
    font-display: swap;
  }

  /* Montserrat-SemiBold */
  @font-face {
    font-family: 'Montserrat-SemiBold';
    src: url('../assets/fonts/Montserrat-SemiBold.ttf');
    font-display: swap;
  }

  /* Montserrat-Light */
  @font-face {
    font-family: 'Montserrat-Light';
    src: url('../assets/fonts/Montserrat-Light.ttf');
    font-display: swap;
  }

  /* Montserrat-Medium */
  @font-face {
    font-family: 'Montserrat-Medium';
    src: url('../assets/fonts/Montserrat-Medium.ttf');
    font-display: swap;
  }

  /* Groteska-Bold */
  @font-face {
    font-family: 'Groteska-Bold';
    src: url('../assets/fonts/Groteska-Bold.ttf');
    font-display: swap;
  }
  *{
    margin: 0;
    padding: 0;
  }
  /* Definir colores */
  :root {
    --color-rojo: #ff0000;
    --color-azul: #0000ff;
    --color-verde: #00ff00;
  }
/* Agrega estilos específicos para slick-slide en estado activo y actual */


/* Puedes agregar estilos adicionales según sea necesario */
.slick-slide {
  /* Estilos generales para todos los slick-slide */
  transition: width 0.5s ease; /* Agrega una transición si deseas animar el cambio de ancho */
}
  /* Estilos generales */
  body {
    font-family: 'Montserrat-Medium', sans-serif;
  
  }
    //Modificar el scroll bar
    ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  ::-webkit-scrollbar-thumb {
    
    background: transparent linear-gradient(180deg, #FFFFFF 0%, #B9DB10 4%, #219348 10%, #00A99D 17%, #3565AC 60%, #3F348B 70%, #9B2690 85%, #BE0C13 100%) 0% 0% no-repeat padding-box;
  }

`;

export default GlobalStyles;
