import React from 'react'
import { ButtonStyle } from './styles/ButtonLoginStyles'
// images 
import GoogleIcon from '../../assets/images/login/google.png'
import { useGoogleLogin } from '@react-oauth/google'

const LoginButtonComponent = ({ login, navigate }) => {
    const onSuccess = (res) => {
        login(res.access_token, navigate)
    }

    const onError = (err) => console.log(err)

    const onLogin = useGoogleLogin({
        onSuccess,
        onError,
        select_account: true
    })

    return (
        <>
            <ButtonStyle onClick={() => onLogin()}>
                <img className='ButtonLogin' src={GoogleIcon} alt="" srcset="" />
                <p className='ContainerTextLogin'>INICIAR SESIÓN CON GOOGLE</p>
            </ButtonStyle>
        </>
    )
}

export default LoginButtonComponent
