import React,{useEffect} from "react";
import { SteeperContainer } from "./FasesStyle";
const SteeperComponent = ({
  apiImage,
  dataPaso,
  footerSelected,
  ActualRouter,
  HandlerIsBackground,
  windowSize
}) => {
  useEffect(() => {
    HandlerIsBackground(false)
  }, [])
  

  return (
    <SteeperContainer>
      <div className="ContainerTopFlexStep">
        <div className="ContianerTopFlexLeft">
          <div
            className="ContainerFaseNamesteep"
            style={{ background: dataPaso?.colores[0]?.color }}
          >
            <img
              width={30}
              src={`${apiImage}${dataPaso?.icono}`}
              alt="Iconopaso"
              srcset=""
            />
            <p
             style={ ActualRouter === "/definicion-estrategica" ? {
              color: "#000"
             }
            :
            {color: "#fff"}
            }
            >
              {ActualRouter === "/definicion-estrategica"
                ? "DEFINICION ESTRATEGICA"
                :
                ActualRouter === "/ideacion"
                ? "IDEACIÓN Y PLAN DE NEGOCIOS"
                :
                ActualRouter === "/desarrollo"
                ? "DESARROLLO"
                :
                ActualRouter === "/fabricacion"
                ? "FABRICACIÓN"
                :
                ActualRouter === "/seguimiento"
                ? "SEGUIMIENTO"
                : ""}
            </p>
          </div>
          <div className="ContainerTitleSteep"  style={{borderBottomColor: dataPaso?.colores[0].color}}>
            <p>
              {dataPaso?.orden} {dataPaso?.titulo}
            </p>
            <div className="LinetittlePasos" style={{background:dataPaso?.colores[0].color}}></div>
          </div>
          <div className="ContainerObjetivoSteep">
            <h3>Objetivo</h3>
            <p>{dataPaso?.objetivo}</p>
          </div>
          <div className="ContainerAccionesSteep">
            <div>
              <div>
                 <div className="tittleaccionesSteep">
                    <h3>Acciones</h3>
                 </div>
                 <div className="lineAccionesSteep"></div>
              </div>
           
            </div>
          </div>
        </div>
        <div className="ContainerTopFlexRight">
          <div 
          style={windowSize.width <= 837 ? {display: 'none' } : {display: 'block'}}
          className="ContainerImgFlexRight">
            <img
              src={`${apiImage}${dataPaso?.imagen}`}
              alt="ImPasos"
              srcset=""
            />
          </div>
        </div>
        
      </div>
      <div className="ContainerActionsFlexSteep">
                {dataPaso?.acciones?.map((r, index) => {
                  return (
                    <div>
                      <div className="ContainerTittleSteepSteep">
                        <div className="ContainerStepStep">
                          <p>
                            {dataPaso?.orden}.{index + 1}
                          </p>
                        </div>
                        <div className="ContainerTittleSteepSteepr">
                          <p>{r.titulo}</p>
                        </div>
                      </div>
                      <div className="ContainerSteepSteepDesc">
                        <p>{r.descripcion}</p>
                      </div>
                    </div>
                  );
                })}
                  <div 
          style={windowSize.width > 837 ? {display: 'none' } : {display: 'block'}}
          className="ContainerImgFlexRight">
            <img
              src={`${apiImage}${dataPaso?.imagen}`}
              alt="ImPasos"
              srcset=""
            />
          </div>
              </div>
    </SteeperContainer>
  );
};

export default SteeperComponent;
