import React from "react";
import { FooterContainerDesk,FooterContainerMovil } from "./FooterStyles";
import { useNavigate } from "react-router-dom";
const FooterComponent = ({
  data,
  HanlderSelectedFooter,
  footerSelected,
  ActualRouter,
  HanlderSelectedStep,
  SelectedStep,
  windowSize,
  HanlderSelectRouteSteep,
  HandlerIsBackground,
  HanlderShowArrowBack
}) => {
  const navigate = useNavigate();
  const apiImage = process.env.REACT_APP_BASE_URLIMAGE;

  const HanlderSelectSteep = (data) =>{
   
    HanlderShowArrowBack()
    HanlderSelectedStep(data)
    HanlderSelectRouteSteep(data.url)
    HandlerIsBackground(false)
  }

  const HanlderNavigate = (data) => {
  
    HanlderSelectedStep(null)
    if (data === "DEFINICIÓN ESTRATÉGICA") {
      navigate("/definicion-estrategica", { replace: true });
      HandlerIsBackground(true)
    } else if (data === "IDEACIÓN Y PLAN DE NEGOCIOS") {
      navigate("/ideacion", { replace: true });
      HandlerIsBackground(true)
    } else if (data === "DESARROLLO") {
      navigate("/desarrollo", { replace: true });
      HandlerIsBackground(true)
    } else if (data === "FABRICACIÓN") {
      navigate("/fabricacion", { replace: true });
      HandlerIsBackground(true)
    } else if (data === "SEGUIMIENTO") {
      navigate("/seguimiento", { replace: true });
      HandlerIsBackground(true)
    }
    HanlderSelectedFooter(data);
  };

  return (
    windowSize.width <= 854 ?
    <div>
        <FooterContainerMovil>
      <div className="ContainerFooterMovil">
        {data?.map((footerlist) => {
          return (
            <div
              style={
                footerlist.nombre === footerSelected ? { flex: 2.2 } : { flex: 1 }
              }
              className="ContainerItemsFooterMobil"
            >
              <div
                onClick={() => HanlderNavigate(footerlist.nombre)}
                style={
                  footerlist.nombre === footerSelected
                    ? { background: footerlist.color }
                    : { background: "#C6C6C6" }
                }
                className="CircleStarttMobil"
              >
                 <p className="ContainerNunSteep">{footerlist.orden}</p>
              </div>
              <div className="ContainerNameItemFooter">
                <div className="ContainerInfoFooterMobil"
                style={
                  footerlist.nombre === footerSelected
                    ? { color: "#000000" }
                    : { color: "#AAAAAA" }
                }
                >
                 
                  
                </div>
                <div className="ContainerSteppsMovbil">
                  {footerlist.pasos.map((StepInfo) => {
                     
                    return (
                      <div
                        style={
                          footerlist.nombre === footerSelected
                            ? { display: "flex", height: 30 }
                            : { display: "none" }
                        }
                      >
                        {/* <img src={`${apiImage}${StepInfo.icono}`} alt="" srcset="" /> */}
                        {
                          StepInfo.tipo === "paso" ?
                          <div
                          onClick={() => HanlderSelectSteep(StepInfo)}
                          style={
                            SelectedStep?.id === StepInfo.id ? 
                            {
                              background: "#fff",
                              borderColor: StepInfo.colores[0],
                              borderWidth: 7,
                              width: 10,
                              height: 10,
                              marginTop: 0,
                              marginLeft: -3
                            }
                            :
                            { background: StepInfo.colores[1],borderColor: StepInfo.colores[1]}}
                          className="CircleStepMobil"
                        >
                         
                        </div>
                          :
                          StepInfo.tipo === "gate" ?
                            <div 
                            onClick={() => HanlderSelectSteep(StepInfo)}
                            className="gateContianer">
                              <img 
                                width={25}
                              src={`${apiImage}${StepInfo.icono}`} alt="" srcset="" />
                            </div>
                        :
                        StepInfo.tipo === "checkpoint" ?
                        <div 
                        onClick={() => HanlderSelectSteep(StepInfo)}
                        className="gateContianerCheck"
                        // style={{borderColor:StepInfo.colores[0]}}
                        >
                          <img 
                            width={windowSize.width <= 834 ? 20 : 15}
                          src={`${apiImage}${StepInfo.icono}`} alt="" srcset="" />
                        </div>
                        :
                          <p>1</p>
                        }
                        {/* <div
                          style={{ background: StepInfo.colores[0] }}
                          className="CircleStartt"
                        ></div> */}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div className="LineGradientFooterMobil"></div>
    </FooterContainerMovil>
          
    </div>
    :
    <FooterContainerDesk>
      <div className="ContainerFooter">
        {data?.map((footerlist) => {
          return (
            <div
              style={
                footerlist.nombre === footerSelected ? { flex: 2.4 } : { flex: 1 }
              }
              className="ContainerItemsFooter"
            >
              <div
                onClick={() => HanlderNavigate(footerlist.nombre)}
                style={
                  footerlist.nombre === footerSelected
                    ? { background: footerlist.color }
                    : { background: "#C6C6C6" }
                }
                className="CircleStartt"
              ></div>
              <div className="ContainerNameItemFooter">
                <div className="ContainerInfoFooter"
                style={
                  footerlist.nombre === footerSelected
                    ? { color: "#000000" }
                    : { color: "#AAAAAA", fontSize: '10px' }
                }
                >
                  <span>{footerlist.orden}</span>
                  <p
                    style={footerlist.nombre === footerSelected ? {fontSize: 13} : {fontSize: 11}}
                  >{footerlist.nombre}</p>
                </div>
                <div className="ContainerStepps">
                  {footerlist.pasos.map((StepInfo) => {
                     console.log(StepInfo,"INFO")
                    return (
                      <div
                        style={
                          footerlist.nombre === footerSelected
                            ? { display: "flex",
                            color: StepInfo.colores[0] }
                            : { display: "none" }
                        }
                      >
                        {/* <img src={`${apiImage}${StepInfo.icono}`} alt="" srcset="" /> */}
                        {
                          StepInfo.tipo === "paso" ?
                          <div
                          onClick={() => HanlderSelectSteep(StepInfo)}
                          style={
                            SelectedStep?.id === StepInfo.id ? 
                            {
                              background: "#fff",
                              borderColor: StepInfo.colores[1],
                              borderWidth: 7,
                              width: 17,
                              height: 17,
                              marginTop: 0
                            }
                            :
                            { background: StepInfo.colores[1],borderColor: StepInfo.colores[1]}}
                          className="CircleStep"
                        >
                          <div className="tooltip">
          <p>{StepInfo.titulo}</p>
        </div>
                        </div>
                          :
                          StepInfo.tipo === "gate" ?
                          <div className="gateContianerBody">
  <div 
                            onClick={() => HanlderSelectSteep(StepInfo)}
                            className="gateContianer">
                              <img 
                                width={25}
                              src={`${apiImage}${StepInfo.icono}`} alt="" srcset="" />
                            
                            
                            </div>
                            <div className="tooltipLast">
                            <p>{StepInfo.titulo}</p>
        </div>
                            </div>
                        :
                        StepInfo.tipo === "checkpoint" ?
                        <div 
                        onClick={() => HanlderSelectSteep(StepInfo)}
                        className="gateContianerCheck"
                        // style={{borderColor:StepInfo.colores[0]}}
                        >
                          <img 
                            width={25}
                          src={`${apiImage}${StepInfo.icono}`} alt="" srcset="" />
                         <div className="tooltip">
          <p>{StepInfo.titulo}</p>
        </div>
                        </div>
                    :
                          <p>1</p>
                        }
                        {/* <div
                          style={{ background: StepInfo.colores[0] }}
                          className="CircleStartt"
                        ></div> */}
                         
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div className="LineGradientFooter"></div>
    </FooterContainerDesk>
  );
};

export default FooterComponent;
