import styled from "styled-components"

export const ContainerTopBarDesk=styled.div` 

  display: flex;
  flex-direction: row;
  width: 90%;
  margin: auto;
  justify-content: space-between;
  border-bottom: 1px solid #EFEFEF;
  padding-bottom:10px;
  padding-top: 10px;
  z-index: 1;

  .TopBarTodos {
    color: rgb(63 52 139 / 69%);
    font-weight: bold;
    font-family: 'Montserrat-Medium', sans-serif;
    cursor: pointer;
  }

  .TopBarItem {
    font-weight: bold;
    font-family: 'Montserrat-Medium', sans-serif;
    cursor: pointer;
  }

  .ContainerIconMenuProyectos {
    display: flex;
    justify-content: center;
  }

  .ContainerIconMenuProyectos img {
    margin-right: 10px;
  }

  .ContainerIconMenuProyectos p {
    display: flex;
    font-weight: bold;
    font-family: 'Montserrat-Medium', sans-serif;
    justify-content: center;
  }


  //***********************************
  // Estilos para submenu
  //***********************************

  /* Estilos para el contenedor del submenú */
  .SubMenu {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
    padding: 10px;
    position: absolute;
    z-index: 100;
    /* Asegúrate de que se superponga correctamente */
    width: 200px;
    /* Ajusta el ancho si es necesario */
  }

  /* Estilos para los elementos dentro del submenú */
  .SubMenuItem {
    font-size: 14px;
    padding: 8px 12px;
    margin: 5px 0;
    cursor: pointer;
    transition: background-color 0.3s ease;
    display: flex;
    align-items: center;
    color: #333;
    border-bottom: 3px solid #efefef;
  }

  /* Efecto hover en los elementos del submenú */
  .SubMenuItem:hover {
    background-color: #f0f0f0;
    border-radius: 4px;
  }

  /* Números de los elementos del submenú (estilo de numeración) */
  .SubMenuItem::before {
    content: counter(item) ". ";
    /* Estilo de numeración automática */
    counter-increment: item;
    color: #7ab800;
    /* Ajusta el color del número si es necesario */
    font-weight: bold;
    margin-right: 8px;
  }

  /* Reinicia el contador de numeración */
  .SubMenu {
    counter-reset: item;
  }

  
  // Mobil Frist
  @media screen and (min-width: 200px) {
    width: 100%;
    font-size: 12px;
  }

  @media screen and (min-width: 720px) {
    width: 98%;
  }

  @media screen and (min-width: 900px) {}

  @media screen and (min-width: 1200px) {
    font-size: 17px;
    width: 90%;
  }


  /* Pantallas pequeñas de escritorio (laptops y pequeños monitores) */
@media (min-width: 1024px) and (max-width: 1280px) {
  .SubMenu {
    width: 13%; /* Cambia el ancho del submenú */
    padding: 8px; /* Reduce el padding */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15); /* Ajusta la sombra */
  }

  .SubMenuItem {
    font-size: 10px; /* Cambia el tamaño de fuente */
    padding: 6px 10px; /* Ajusta el padding para reducir el tamaño de los elementos */
    margin: 3px 0; /* Reduce el margen entre los elementos */
  }
}

/* Pantallas medianas de escritorio (laptops grandes y monitores medianos) */
@media (min-width: 1281px) and (max-width: 1440px) {
  .SubMenu {
    width: 13%; /* Cambia el ancho del submenú */
    padding: 8px; /* Reduce el padding */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15); /* Ajusta la sombra */
  }

  .SubMenuItem {
    font-size: 11px; /* Cambia el tamaño de fuente */
    padding: 6px 10px; /* Ajusta el padding para reducir el tamaño de los elementos */
    margin: 3px 0; /* Reduce el margen entre los elementos */
  }
}

/* Pantallas grandes de escritorio (monitores grandes y Full HD) */
@media (min-width: 1441px) and (max-width: 1920px) {
  .SubMenu {
    width: 13%; /* Cambia el ancho del submenú */
    padding: 10px; /* Reduce el padding */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15); /* Ajusta la sombra */
  }

  .SubMenuItem {
    font-size: 12px; /* Cambia el tamaño de fuente */
    padding: 8px 12px; /* Ajusta el padding para reducir el tamaño de los elementos */
    margin: 3px 0; /* Reduce el margen entre los elementos */
  }
}

/* Pantallas extra grandes (monitores UltraWide o 4K) */
@media (min-width: 1921px) {
  .SubMenu {
    width: 13%; /* Cambia el ancho del submenú */
    padding: 10px; /* Reduce el padding */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15); /* Ajusta la sombra */
  }

  .SubMenuItem {
    font-size: 11px; /* Cambia el tamaño de fuente */
    padding: 8px 12px; /* Ajusta el padding para reducir el tamaño de los elementos */
    margin: 3px 0; /* Reduce el margen entre los elementos */
  }
}

  /* Pantallas menores a 1024px (por si necesitas aplicar los estilos también a tablets o monitores más pequeños) */
  @media (max-width: 1024px) {
    .SubMenu {
      width: 15%; /* Cambia el ancho del submenú */
      padding: 8px; /* Reduce el padding */
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15); /* Ajusta la sombra */
    }

    .SubMenuItem {
      font-size: 10px; /* Cambia el tamaño de fuente */
      padding: 6px 10px; /* Ajusta el padding para reducir el tamaño de los elementos */
      margin: 3px 0; /* Reduce el margen entre los elementos */
    }
  }


` 

export const ContainerTopBarMovil=styled.div` 

  width: 90%;
  border-bottom: 1px solid #EFEFEF;
  margin: auto;
  position: relative;
  margin-top: 20px;
    
  // Mobil Frist
  @media screen and (min-width: 200px) {
    .FloatMenuMovilHome {
      position: absolute;
      background: #fff;
      border-radius: 10px;
      z-index: 2;
      width: 100%;
      top: 20px;
      left: 0px;
      box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px;
      // sss 
    }

    .ContainerArrowDownItem {
      display: flex;
      justify-content: space-between;
    }

    .ItemMenuTopBarMovil {
      flex: 1;
      padding-bottom: 10px;
    }

    .ItemMenuTopBarMovil p {
      text-align: center;
      font-weight: bold;
      font-family: 'Montserrat-Medium', sans-serif;
      font-size: 12px;
      margin-bottom: 15px;
    }

    .ContainerIconMenuProyectos {
      display: flex;
      justify-content: center;
    }

    .ContainerIconMenuProyectos img {
      margin-right: 10px;
    }

    .ContainerImgMenumovil {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  @media screen and (min-width: 720px) {}

  @media screen and (min-width: 900px) {}

  @media screen and (min-width: 1200px) {}

    //***********************************
  // Estilos para submenu
  //***********************************

  /* Estilos para el contenedor del submenú */
  .SubMenu {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
    padding: 10px;
    position: absolute;
    z-index: 100;
    /* Asegúrate de que se superponga correctamente */
    width: 200px;
    /* Ajusta el ancho si es necesario */
    left: 15%;
  }

  /* Estilos para los elementos dentro del submenú */
  .SubMenuItem {
    font-size: 14px;
    padding: 8px 12px;
    margin: 5px 0;
    cursor: pointer;
    transition: background-color 0.3s ease;
    display: flex;
    align-items: center;
    color: #333;
    border-bottom: 3px solid #efefef;
  }

  /* Efecto hover en los elementos del submenú */
  .SubMenuItem:hover {
    background-color: #f0f0f0;
    border-radius: 4px;
  }

  /* Números de los elementos del submenú (estilo de numeración) */
  .SubMenuItem::before {
    content: counter(item) ". ";
    /* Estilo de numeración automática */
    counter-increment: item;
    color: #7ab800;
    /* Ajusta el color del número si es necesario */
    font-weight: bold;
    margin-right: 8px;
  }

  /* Reinicia el contador de numeración */
  .SubMenu {
    counter-reset: item;
  }


`