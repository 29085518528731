import React,{useState,useEffect} from 'react'

const useLogin = () => {
    const [ShowSplash, setShowSplash] = useState(true)

    useEffect(() => {
        setTimeout(() => {
            setShowSplash(false)
        }, 2000);
    }, [ShowSplash])
    
  return {
    ShowSplash
  }
}

export default useLogin