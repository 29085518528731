// useAxiosGet.js
import { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { CosmoContext } from '../../context/CosmoContext';

const useCMS = () => {
  const [Datamanual, setDataManual] = useState(null);
  const [loadingApi, setLoading] = useState(true);
  const [DataMatriz, setDataMatriz] = useState()
  const [DataMatrizEstructura, setDataMatrizEstructura] = useState()
  const [error, setError] = useState(null);
  const [dataFases, setdataFases] = useState(null)
  const [DataSteep, setDataSteep] = useState(null)
  const [DataObjetivo, setDataObjetivo] = useState(null)
  const [DataProyectos, setDataProyectos] = useState(null)
  const {HanlderFases} = useContext(CosmoContext)

  const LoadDataManual = async (url) => {
    try {
      const response = await axios.get(url);
      setDataManual(response.data.data);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const LoadDataMatriz = async (url) => {
    try {
      const response = await axios.get(url);
      setDataMatriz(response.data.data);
      setDataMatrizEstructura(response.data.data.estructura)
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };
  const LoadFases = async (url) => {
    try {
      const response = await axios.get(url);
      HanlderFases(response.data.data);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const LoadSteep = async (url) => {
    try {
      const response = await axios.get(url);
      setDataSteep(response.data.data);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const LoadObjetivo = async (url) => {
    try {
      const response = await axios.get(url);
      setDataObjetivo(response.data.data);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };
  const LoadProyectos = async (url) => {
    try {
      const response = await axios.get(url);
      setDataProyectos(response.data.data);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };
  
  return {LoadDataManual, Datamanual, loadingApi, error,
    DataMatriz,
    DataMatrizEstructura,
    LoadDataMatriz,
    dataFases,
    LoadFases,
    LoadSteep,
    DataSteep,
    LoadObjetivo,
    DataObjetivo,
    DataProyectos,
LoadProyectos
  };
};

export default useCMS;
