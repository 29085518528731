import React,{useState,useEffect,useContext} from 'react'
import {ProyectoStyles} from '../components/Proyecto/ProyectStyles'
import ProyectoComponent from '../components/Proyecto/ProyectoComponent'
import { useNavigate } from "react-router-dom";
import { CosmoContext } from '../context/CosmoContext';
import { useLocation } from "react-router-dom";
const Tipoproyecto = ({
    DataProyectos,
    windowSize,
    HanlderSelectRouterMenu,OpenModalMarcoPrime
}) => {
  const { HandlerRoute, 
    ActualRouter, 
    HandlerIsBackground, 
    HanlderSelectedFooter,
   } =
    useContext(CosmoContext);
  const location = useLocation();
  const apiImage = process.env.REACT_APP_BASE_URLIMAGE;
  const [DataProyecto, setDataProyecto] = useState(DataProyectos?.find(objeto => objeto.titulo === "ARTE"))

  const HanlderChangueProyecto = (data) => {
    setDataProyecto(data)
  }
  const buscarProyecto = (titulo) => {
    return DataProyectos?.find(objeto => objeto.titulo === titulo);
  };

  useEffect(() => {
    const objetoArtes = buscarProyecto("ARTES");
    setDataProyecto(objetoArtes);
  }, [DataProyectos]);
  
  useEffect(() => {
    HandlerRoute(location.pathname);
    HanlderSelectedFooter(null)
    HandlerIsBackground(false)
  }, [ActualRouter]);

  return (
    <ProyectoStyles>
        <ProyectoComponent
        OpenModalMarcoPrime={OpenModalMarcoPrime}
          DataProyectos={DataProyectos}
          windowSize={windowSize}
          apiImage={apiImage}
          DataProyecto={DataProyecto}
          HanlderChangueProyecto={HanlderChangueProyecto}
          buscarProyecto={buscarProyecto}
          HanlderSelectRouterMenu={HanlderSelectRouterMenu}
        ></ProyectoComponent>
        
    </ProyectoStyles>
  )
}

export default Tipoproyecto