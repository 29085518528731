import React, { useState, useEffect } from "react";
import ReactModal from "react-modal";
import "./prueba.css";
import { ModalLayout, ModalManualLayout } from "./ModalStyles";
import useCMS from "../../../api/CMS/useCMS";
import CloseImg from "../../../assets/images/menu/CloseSvg.svg";
// CMS

const ModalMatriz = ({ modal, closemodal, dataRoute, Tipo, windowSize }) => {
  const { DataMatriz, DataMatrizEstructura, LoadDataMatriz } = useCMS();
  const apiUrl = process.env.REACT_APP_BASE_URL;
  const apiImage = process.env.REACT_APP_BASE_URLIMAGE;
  const [modalStyles, setModalStyles] = useState({
    content: {
      width: "100%",
      left: "0%",
      height: "100vh",
      top: "0%",
      transform: "translate(-0%, -0%) translateX(-100%)",
      transition: "transform 0.3s ease-in-out",
      borderTopRightRadius: "25px",
      overflow: "scroll",
      background: "none",
    },
    overlay: {
      // backgroundColor: "rgba(0, 0, 0, 0.5)",
      opacity: 0,
      transition: "opacity 0.3s ease-in-out",
    },
  });

  useEffect(() => {
    if (modal) {
      document.body.style.overflow = "hidden";
      setModalStyles((prevStyles) => ({
        ...prevStyles,
        content: {
          ...prevStyles.content,
          transform: "translate(-0%, -0%) translateX(0%)",
        },
        overlay: {
          ...prevStyles.overlay,
          opacity: 1,
          zIndex: 99,
        },
      }));
    } else {
      document.body.style.overflow = "auto";
      setModalStyles((prevStyles) => ({
        ...prevStyles,
        content: {
          ...prevStyles.content,
          transform: "translate(-0%, -0%) translateX(-100%)",
        },
      }));
    }
  }, [modal]);

  useEffect(() => {
    if (Tipo === "Matriz" && modal) {
      LoadDataMatriz(`${apiUrl}matriz-de-corcig`);
    } else {
    }
  }, [modal]);

  const itemsTop = DataMatrizEstructura?.slice(0, 2);
  const itemsBottom = DataMatrizEstructura?.slice(2);


  return (
    <ReactModal
      isOpen={modal}
      contentLabel="onRequestClose Example"
      onRequestClose={closemodal}
      shouldCloseOnOverlayClick={false}
      style={modalStyles}
      ariaHideApp={false}
    >
      <div className={modal ? "blur-background" : ""}></div>
      <ModalLayout>
        <ModalManualLayout>
          <div
            className={
              windowSize.width <= 837
                ? "CloseBotonModalMobil"
                : "CloseBotonModalDesk"
            }
            onClick={() => closemodal()}
          >
            <img src={CloseImg} alt="" srcset="" />
          </div>
          <div className="ContainerTitle">
            <h3>Matriz de CoRCIG</h3>
            <div className="LineGradientModalTitle"></div>
          </div>
         
          <div className="BodyInfomodal">
          <div className="ContainerCopyModal">
            <p>{DataMatriz?.copy}</p>
          </div>
            <diiv className="conatinerInfoModal">
              <div className="ContainerInfoModalLeft">
                {itemsTop?.map((res) => {
                 

                  return (
                    <div className="ContainerBodyItemModal">
                      <div className="ContainerTitleItemModal">
                        <img
                          width={windowSize.width <= 837 ? 20 : 35}
                          src={`${apiImage}${res.icono}`}
                          alt=""
                          srcset=""
                        />
                        <p>{res.titulo}</p>
                      </div>
                      <div className="ContainerDeskriptionModal">
                        <p>{res.descripcion}</p>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="ContainerInfoModalRight">
                {itemsBottom?.map((res) => {
                  return (
                    <div className="ContainerBodyItemModal">
                      <div className="ContainerTitleItemModal">
                        <img
                          width={windowSize.width <= 837 ? 20 : 35}
                          src={`${apiImage}${res.icono}`}
                          alt=""
                          srcset=""
                        />
                        <p>{res.titulo}</p>
                      </div>
                      <div className="ContainerDeskriptionModal">
                        <p>{res.descripcion}</p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </diiv>
          </div>
        </ModalManualLayout>
      </ModalLayout>
    </ReactModal>
  );
};

export default ModalMatriz;
