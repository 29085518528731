import React, { createContext, useState, useEffect } from 'react';
export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(localStorage.getItem('user'));
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        isAuthenticated().then((user) => {
            setUser(user);
            setLoading(false);
        });
    }, []);

    const login = async (token, navigate) => {
        setUser(token)
        localStorage.setItem('user', token);
        navigate("/")
    };

    const logout = () => {
        localStorage.removeItem('user');
        setUser(null)
    };

    const isAuthenticated = async () => {
        try {
            if (!localStorage.getItem("user")) return null
            const userUri = "https://www.googleapis.com/oauth2/v3/userinfo"
            const req = await fetch(userUri, {
                headers: {
                    "Authorization": `Bearer ${localStorage.getItem('user')}`
                }
            })
            const data = await req.json()
            return data
        } catch (_) {
            return null
        }
    };

    const authContextValue = {
        user,
        login,
        logout,
        isAuthenticated,
        loading,
    };

    return (
        <AuthContext.Provider value={authContextValue}>
            {loading ? <p>Loading...</p> : children}
        </AuthContext.Provider>
    );
};
