import styled from "styled-components";
import { motion } from "framer-motion";
// globalstyles
import GlobalStyles from "../../../theme/GlobalStyles";

export const ModalLayout = styled.div`
  margin: auto;
  margin-top: 3%;
  padding-bottom: 30px;

  // Mobil Frist
  @media screen and (min-width: 200px) {
    width: 100%;
  }
  @media screen and (min-width: 720px) {
  }
  @media screen and (min-width: 900px) {
    width: 90%;
  }
  @media screen and (min-width: 1200px) {
  }

  // LANSCAPE Mobil
  @media screen and (min-width: 838px) and (orientation: landscape) {
    // DESK
  }
  @media screen and (min-width: 930px) and (orientation: landscape) {
  }
`;
export const ModalManualLayout = styled.div`
  .CloseBotonModalDesk {
    position: absolute;
    top: 10%;
    right: 2%;
    background: #fff;
    padding-right: 15px;
    padding-left: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 25px 0px 0px 25px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px;
    place-items: center;
    cursor: pointer;
  }
  .CloseBotonModalMobil {
    position: absolute;
    top: 2%;
    right: 10%;

    cursor: pointer;
  }
  .ContainerTitle h3 {
    padding-left: 13px;
    font-size: 28px;
    color: rgb(155, 38, 144);
    font-family: "Montserrat-Bold", sans-serif;
    padding-bottom: 5px;
    width: 100%;
    position: relative;
  }
  .LineGradientModalTitle {
    position: absolute;
    content: "";
    background: red;
    width: 10%;
    height: 5px;
    left: 7%;
    background: transparent
      linear-gradient(
        105deg,
        #ffffff 0%,
        #b9db10 4%,
        #219348 10%,
        #00a99d 17%,
        #3565ac 60%,
        #3f348b 70%,
        #9b2690 85%,
        #be0c13 100%
      )
      0% 0% no-repeat padding-box;
  }
  .BodyInfomodal {
    margin-top: 5%;
  }
  .conatinerInfoModal {
    display: flex;
    justify-content: space-around;
  }
  .ContainerTitleItemModal {
    display: flex;
    align-items: center;
  }
  .ContainerTitleItemModal p {
    margin-left: 5px;
    color: rgb(63, 52, 139);
    font-family: "Montserrat-Bold", sans-serif;
    color: rgb(63, 52, 139);
    font-weight: bold;
    font-size: 17px;
  }
  .ContainerBodyItemModal {
    padding-bottom: 20px;
  }
  .ContainerDeskriptionModal p {
    
    color: rgb(64, 63, 63);
    line-height: 18px;
    margin-top: 10px;
  }
  .ContainerCopyModal {
    margin-top: -30px;
    padding-bottom: 20px;
  }
  .ContainerCopyModal p {
    font-size: 13px;
    font-family: "Montserrat-Bold", sans-serif;
    color: rgb(2, 26, 112);
    line-height: 18px;
    font-weight: bold;
  }
  // Mobil Frist
  @media screen and (min-width: 200px) {
    .conatinerInfoModal {
      flex-direction: column;
    }
    .ContainerInfoModalLeft {
      width: 100%;
    }
    .ContainerInfoModalRight {
      width: 100%;
    }
    .LineGradientModalTitle {
      left: 10%;
      width: 70%;
    }
    .ContainerCopyModal {
      margin-top: 10px;
      padding-bottom: 20px;
    }
    .ContainerTitle h3 {
      margin-top: 40px;
    font-size: 18px;
    width: 85%;
    }
    .BodyInfomodal {
      margin-top: 5%;
      width: 90%;
    }
    .ContainerDeskriptionModal p {
    font-size: 14px;

  }
  }
  @media screen and (min-width: 720px) {
  }
  @media screen and (min-width: 900px) {
    .ContainerTitle h3 {
      font-size: 28px;
    }
    .conatinerInfoModal {
      flex-direction: row;
    }
    .ContainerInfoModalLeft {
      width: 42%;
    }
    .ContainerInfoModalRight {
      width: 50%;
    }
    .LineGradientModalTitle {
      left: 7%;
      width: 10%;
    }
    .ContainerCopyModal {
      margin-top: 0px;
      padding-bottom: 20px;
    }
        .BodyInfomodal {
      margin-top: 4%;
      width: 100%;
    }
    .ContainerCopyModal p {
    font-size: 1rem;
    
  }
  .ContainerDeskriptionModal p {
    font-size: 1rem;
    line-height: 30px;
  }
  }
  @media screen and (min-width: 1200px) {
  }

  // LANSCAPE Mobil
  @media screen and (min-width: 838px) and (orientation: landscape) {
    // DESK
  }
  @media screen and (min-width: 930px) and (orientation: landscape) {
  }
`;
